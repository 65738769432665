import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Card,
  Progress,
  Select,
  Radio,
  Popover,
  message,
  Table,
  DatePicker
} from "antd";
import moment from "moment";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import * as userActions from "../../../store/actions/user.action";
import Chart from "./chart";
import TableWrap from "./table";
import "./index.less";
import {
  billUploadDetailsApi,
  findDataAnalysisApi,
  findTypeRatioReportApi,
  findEquityFundsReportApi,
  findRevenueCollectionDataAnalysisApi
} from "../../../api/home";
import { formatMoney } from "../../../utils";
const DATE = new Date().getFullYear();
class Home extends Component {
  state = {
    /* --------start  已上传账单---------- */
    yearNum: DATE,
    yearParams: {
      firstDay: `${DATE}-01-01`,
      lastDay: `${DATE}-12-31`
    },
    billBranchId: this.props.departmentData
      ? this.props.departmentData.map(item => item.branchId)
      : [],
    uploadedData: [],
    /* ----------end    ------------------ */

    /*------------start  收支明细  ---------- */
    currentRadio: 3,
    expendBranchId: this.props.departmentData
      ? this.props.departmentData.map(item => item.branchId)
      : [],
    histogramReport: [],
    ratioReportResp: null,
    /* ----------end    ------------------ */
    isRequest: false,
    /*------------start  收支明细  ---------- */
    equityBranchId: this.props.departmentData
      ? this.props.departmentData.map(item => item.branchId)
      : [],
    columns: [
      {
        title: "分类",
        dataIndex: "type"
      },
      {
        title: "总收入",
        dataIndex: "income",
        render: text => (
          <span>{text ? `${formatMoney(text / 10000, 0)}万` : "--"}</span>
        )
      },
      {
        title: "收入占比",
        dataIndex: "incomeRatio",
        render: text => <span>{text ? text + "%" : "--"}</span>
      },
      {
        title: "总支出",
        dataIndex: "expenditure",
        render: text => (
          <span>{text ? `${formatMoney(text / 10000, 0)}万` : "--"}</span>
        )
      },
      {
        title: "支出占比",
        dataIndex: "expenditureRatio",
        render: text => <span>{text ? text + "%" : "--"}</span>
      }
    ],
    equityTableData: [],
    selectTimer: {
      beginTime: moment().startOf("month").format("YYYY-MM-DD"),
      endTime: moment().endOf("month").format("YYYY-MM-DD")
    },
    isLoading: false,
    yearM: moment().year(),
    /* ----------end    ------------------ */

    /* ---------------start  业务回款报表 ----------- */
    paymentBranchId: this.props.departmentData
      ? this.props.departmentData.map(item => item.branchId)
      : [],
    paymentReport: [],
    paymentCurrentVal: 3,
    paymentYearM: moment().year()
    /* --------------- end ------------- */
  };
  // 切换时间查看对账单
  changeYearNum = type => {
    let yearNumCurrent = this.state.yearNum;
    if (type === "increment") {
      yearNumCurrent += 1;
    } else {
      yearNumCurrent -= 1;
    }
    let params = {
      firstDay: `${yearNumCurrent}-01-01`,
      lastDay: `${yearNumCurrent}-12-31`
    };
    this.setState({
      yearNum: yearNumCurrent,
      yearParams: params
    });
    message.loading("加载中...");
    this.getBillUploadDetails({ ...params, branchId: this.state.billBranchId });
  };
  // 选择部门数据
  handleChange = (branchId, val) => {
    message.loading("加载中...");
    if (val === "bill") {
      this.getBillUploadDetails({ ...this.state.yearParams, branchId });
      this.setState({
        billBranchId: branchId
      });
    }
  };
  // 全选
  allSelectHandle = type => {
    let branchId = this.props.departmentData
      ? this.props.departmentData.map(item => item.branchId)
      : [];
    if (type === "bill") {
      this.setState(
        () => ({
          billBranchId: branchId
        }),
        () => {
          this.getBillUploadDetails({
            ...this.state.yearParams,
            branchId: this.state.billBranchId
          });
        }
      );
    } else if (type === "expend") {
      this.setState(
        () => ({
          expendBranchId: branchId
        }),
        () => {
          this.getAnalysisData({ type: this.state.currentRadio });
        }
      );
    } else if (type === "equity") {
      this.setState(
        () => ({
          equityBranchId: branchId
        }),
        () => {
          this.getEquityFundsReport(this.state.selectTimer);
        }
      );
    } else if (type === "payment") {
      this.setState(
        () => ({
          paymentBranchId: branchId
        }),
        () => {
          this.getPaymentData({ type: this.state.paymentCurrentVal });
        }
      );
    }
  };
  // 反选
  unSelectHandle = type => {
    const {
      billBranchId,
      yearParams,
      expendBranchId,
      equityBranchId,
      currentRadio,
      paymentCurrentVal,
      paymentBranchId
    } = this.state;
    if (type === "bill") {
      const branchIdArr = this.noSelect(billBranchId);
      this.setState(
        () => ({
          billBranchId: branchIdArr
        }),
        () =>
          this.getBillUploadDetails({ ...yearParams, branchId: branchIdArr })
      );
    } else if (type === "expend") {
      this.setState(
        () => ({
          expendBranchId: this.noSelect(expendBranchId)
        }),
        () => {
          this.getAnalysisData({ type: currentRadio });
        }
      );
    } else if (type === "equity") {
      this.setState(
        () => ({
          equityBranchId: this.noSelect(equityBranchId)
        }),
        () => {
          this.getEquityFundsReport(this.state.selectTimer);
        }
      );
    } else if (type === "payment") {
      this.setState(
        () => ({
          paymentBranchId: this.noSelect(paymentBranchId)
        }),
        () => {
          this.getPaymentData({ type: paymentCurrentVal });
        }
      );
    }
  };
  noSelect = arr => {
    let newArr = [];
    this.props.departmentData.forEach(item => {
      let it = arr.findIndex(rI => rI === item.branchId);
      if (it < 0) {
        newArr.push(item.branchId);
      }
    });
    return newArr.length === 0
      ? [this.props.departmentData[0].branchId]
      : newArr;
  };
  // 部门选择
  selectContent = type => {
    if (this.props.departmentData) {
      const options = this.props.departmentData.map(item => ({
        label: item.branchName,
        value: item.branchId
      }));
      let branchId;
      if (type === "bill") {
        branchId = this.state.billBranchId;
      } else if (type === "expend") {
        branchId = this.state.expendBranchId;
      } else if (type === "equity") {
        branchId = this.state.equityBranchId;
      } else if (type === "payment") {
        branchId = this.state.paymentBranchId;
      }
      return (
        <Select
          mode="multiple"
          style={{ width: "220px" }}
          value={branchId}
          options={options}
          onChange={e => this.selectChange(e, type)}
          maxTagCount="responsive"
          placeholder="请选择部门"
          dropdownRender={menu => (
            <div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  textAlign: "center",
                  padding: 8,
                  cursor: "pointer"
                }}
              >
                <span
                  style={{ flex: 1 }}
                  onClick={() => this.allSelectHandle(type)}
                >
                  全选
                </span>
                <span
                  style={{ flex: 1 }}
                  onClick={() => this.unSelectHandle(type)}
                >
                  反选
                </span>
              </div>
              {menu}
            </div>
          )}
        />
      );
    }
  };
  // 部门多选查看收支明细
  selectChange = (val, type) => {
    if (val.length > 0) {
      // message.loading('加载中...')
      if (type === "bill") {
        this.setState({
          billBranchId: val
        });
        this.getBillUploadDetails({ ...this.state.yearParams, branchId: val });
      } else if (type === "expend") {
        this.setState(
          () => ({ expendBranchId: val }),
          () => {
            this.getAnalysisData({ type: this.state.currentRadio });
          }
        );
      } else if (type === "equity") {
        this.setState(
          () => ({ equityBranchId: val }),
          () => {
            this.getEquityFundsReport(this.state.selectTimer);
          }
        );
      } else if (type === "payment") {
        this.setState(
          () => ({ paymentBranchId: val }),
          () => {
            this.getPaymentData({ type: this.state.paymentCurrentVal });
          }
        );
      }
    }
  };
  // 挂载完成 生命周期
  componentDidMount() {
    this.getBillUploadDetails({
      firstDay: `${new Date().getFullYear()}-01-01`,
      lastDay: `${new Date().getFullYear()}-12-31`,
      branchId: this.state.billBranchId
    });
    this.getAnalysisData({ type: this.state.currentRadio });
    this.getEquityFundsReport(this.state.selectTimer);
    this.getPaymentData({ type: this.state.paymentCurrentVal });
  }

  // 监听组件内部状态的变化:
  componentDidUpdate(prevProps, prevState) {
    // 参数分别为改变之前的数据状态对象
    if (
      prevProps.commonStore.isRefreshHomeData !==
      this.props.commonStore.isRefreshHomeData
    ) {
      this.getBillUploadDetails({
        ...this.state.yearParams,
        branchId: this.state.billBranchId
      });
      this.getAnalysisData({ type: this.state.currentRadio });
      this.getPaymentData({ type: this.state.paymentCurrentVal });
    }
  }

  // 获取已上传账单进度详情
  getBillUploadDetails = params => {
    if (!this.state.isRequest) {
      this.setState({
        isRequest: true
      });
      billUploadDetailsApi(params).then(res => {
        message.destroy();
        let uploadedData = [];
        if (res.code === 200) {
          let result = res.data;
          for (let i = 0; i < 12; i++) {
            uploadedData.push({
              month: `${i + 1}月`,
              value: result[i + 1] ? (result[i + 1].length / 30) * 100 : 0,
              num: result[i + 1] ? result[i + 1].length : 0
            });
          }
        }
        this.setState({
          uploadedData,
          isRequest: false
        });
      });
    }
  };
  // 获取收支明细详情
  getAnalysisData = val => {
    if (this.state.expendBranchId.length > 0) {
      const params = {
        ...val,
        branchId: this.state.expendBranchId,
        type: val.type
      };
      if (val.type === 3) {
        params.year = this.state.yearM;
      }
      if (!this.state.isRequest) {
        this.setState({
          isRequest: true
        });
        findDataAnalysisApi(params).then(res => {
          message.destroy();
          if (res.code === 200 && res.data) {
            this.ratioReportRespTimer = {
              endTime: res.data.ratioReportResp.endTime,
              beginTime: res.data.ratioReportResp.beginTime
            };
            this.setState({
              histogramReport: res.data.histogramReport,
              ratioReportResp: res.data.ratioReportResp
            });
          }
          this.setState({
            isRequest: false
          });
        });
      } else {
        message.destroy();
      }
    }
  };
  /* 业务回款 */
  getPaymentData = val => {
    if (this.state.paymentBranchId.length > 0) {
      const params = {
        ...val,
        branchId: this.state.paymentBranchId,
        type: val.type
      };
      if (val.type === 3) {
        params.year = this.state.paymentYearM;
      }
      findRevenueCollectionDataAnalysisApi(params).then(res => {
        message.destroy();
        if (res.code === 200 && res.data) {
          this.setState({
            paymentReport: res.data
          });
        }
        message.destroy();
      });
    }
  };
  // 获取股票基金报表
  getEquityFundsReport = params => {
    this.setState({
      isLoading: true
    });
    findEquityFundsReportApi({
      ...params,
      branchId: this.state.equityBranchId
    }).then(res => {
      if (res.code === 200) {
        this.setState({
          equityTableData: res.data,
          isLoading: false
        });
      }
    });
  };
  // 筛选时间查询收支明细
  onRadioChange = val => {
    this.getAnalysisData({ type: val.target.value });
    this.setState({
      currentRadio: val.target.value
    });
    message.loading("加载中...");
  };
  /* TODO 业务回款统计单选事件*/
  onRadioPayment = val => {
    this.getPaymentData({ type: val.target.value });
    this.setState({
      paymentCurrentVal: val.target.value
    });
    message.loading("加载中...");
  };
  equityRadioChange = val => {
    let timerObj = {};
    let date = new Date();
    switch (val.target.value) {
      case 1:
        timerObj.beginTime =
          moment()
            .weekYear(date.getFullYear())
            .month(date.getMonth())
            .format("YYYY-MM") + "-01";
        timerObj.endTime = moment([date.getFullYear(), 0, 31])
          .month(date.getMonth())
          .format("YYYY-MM-DD");
        break;
      case 2:
        timerObj.beginTime =
          moment().weekYear(date.getFullYear()).format("YYYY") + "-01-01";
        timerObj.endTime =
          moment().weekYear(date.getFullYear()).format("YYYY") + "-12-31";
        break;
      case 3:
        timerObj.beginTime =
          moment()
            .weekYear(date.getFullYear())
            .month(date.getMonth() - 1)
            .format("YYYY-MM") + "-01";
        timerObj.endTime = moment([date.getFullYear(), 0, 31])
          .month(date.getMonth() - 1)
          .format("YYYY-MM-DD");
        break;
      case 4:
        timerObj.beginTime =
          moment()
            .weekYear(date.getFullYear() - 1)
            .format("YYYY") + "-01-01";
        timerObj.endTime =
          moment()
            .weekYear(date.getFullYear() - 1)
            .format("YYYY") + "-12-31";
        break;
      default:
        break;
    }
    this.setState({
      selectTimer: timerObj
    });
    this.getEquityFundsReport(timerObj);
  };
  // 统计图联动
  childrenHandle = type => {
    if (type === "expend") {
      return params => {
        this.ratioReportRespTimer = params;
        findTypeRatioReportApi({
          ...params,
          type: this.state.currentRadio,
          branchId: this.state.expendBranchId
        }).then(res => {
          if (res.code === 200) {
            this.setState({
              ratioReportResp: res.data
            });
          }
        });
      };
    } else if (type === "payment") {
      return () => {
        /* TODO */
        console.log("payment");
      };
    }
  };
  onChange = val => {
    if (val) {
      this.setState(
        () => ({
          yearM: moment(val).format("YYYY")
        }),
        () => {
          message.loading("加载中...");
          this.getAnalysisData({ type: this.state.currentRadio });
        }
      );
    }
  };
  isSelectDom = () => {
    if (this.state.currentRadio === 3) {
      return (
        <span style={{ marginRight: 20 }}>
          选择年份：
          <DatePicker
            className="picker-year"
            bordered={false}
            style={{ width: 90 }}
            defaultValue={moment(this.state.yearM, "YYYY")}
            onChange={this.onChange}
            picker="year"
          />
        </span>
      );
    }
  };
  /* 渲染数据 */
  render() {
    const {
      yearM,
      yearNum,
      uploadedData,
      histogramReport,
      ratioReportResp,
      currentRadio,
      equityTableData,
      columns,
      isLoading,
      paymentReport,
      paymentYearM,
      paymentCurrentVal
    } = this.state;
    const ratioReportRespTimer = this.ratioReportRespTimer;
    return (
      <div>
        <span style={{ position: "fixed", left: 9999 }}>
          {this.props.commonStore.isRefreshHomeData}
        </span>
        <Card title="已上传对账单" extra={this.selectContent("bill")}>
          <div className="progress-container">
            <div className="header">
              <span onClick={() => this.changeYearNum("decrement")}>
                <LeftOutlined style={{ fontSize: "10px", cursor: "pointer" }} />
              </span>
              <span>{yearNum}</span>
              <span onClick={() => this.changeYearNum("increment")}>
                <RightOutlined
                  style={{ fontSize: "10px", cursor: "pointer" }}
                />
              </span>
            </div>
            <div className="content">
              {uploadedData.map(item => {
                return (
                  <Popover
                    key={item.month}
                    content={`上传次数：${item.num}`}
                    title={item.month}
                    trigger="hover"
                  >
                    <div className="item">
                      <Progress
                        showInfo={false}
                        strokeLinecap="square"
                        trailColor="#dbdbdb"
                        percent={item.value}
                      />
                      <span>{item.month}</span>
                    </div>
                  </Popover>
                );
              })}
            </div>
            <div className="footer">
              <span>未上传</span>
              <span>已上传</span>
            </div>
          </div>
        </Card>
        {/* 收支明细 */}
        <Card
          className="tabs-card-container"
          title="收支明细"
          extra={this.selectContent("expend")}
          style={{ marginTop: "15px" }}
        >
          <div className="details-container">
            <div className="left-charts">
              <Radio.Group
                defaultValue={3}
                buttonStyle="solid"
                style={{ margin: "20px 0 0" }}
                onChange={this.onRadioChange}
              >
                <Radio.Button value={1}>年</Radio.Button>
                <Radio.Button value={2}>季</Radio.Button>
                <Radio.Button value={3}>月</Radio.Button>
                <Radio.Button value={4}>周</Radio.Button>
                <Radio.Button value={5}>日</Radio.Button>
              </Radio.Group>
              <Chart
                pageData={histogramReport}
                yearM={yearM}
                currentRadio={currentRadio}
                chartId={"main"}
                getData={this.childrenHandle("expend")}
              />
            </div>
            <div className="right-table">
              <div style={{ padding: "0 16px" }}>
                {this.isSelectDom()}
                <span>
                  {ratioReportRespTimer ? ratioReportRespTimer.beginTime : ""}~
                  {ratioReportRespTimer ? ratioReportRespTimer.endTime : ""}
                </span>
              </div>
              <TableWrap pageData={ratioReportResp} />
            </div>
          </div>
        </Card>
        {/* 业务回款报表 */}
        <Card
          className="tabs-card-container"
          title="业务回款报表"
          extra={this.selectContent("payment")}
          style={{ marginTop: "15px" }}
        >
          <div className="details-container">
            <div className="left-charts" /* style={{ width: "70%" }} */>
              <Radio.Group
                defaultValue={3}
                buttonStyle="solid"
                style={{ margin: "20px 0 0" }}
                onChange={this.onRadioPayment}
              >
                <Radio.Button value={1}>年</Radio.Button>
                <Radio.Button value={2}>季</Radio.Button>
                <Radio.Button value={3}>月</Radio.Button>
                <Radio.Button value={4}>周</Radio.Button>
                <Radio.Button value={5}>日</Radio.Button>
              </Radio.Group>
              <Chart
                pageData={paymentReport}
                yearM={paymentYearM}
                chartId={"payment"}
                currentRadio={paymentCurrentVal}
                getData={this.childrenHandle("payment")}
              />
            </div>
            {/* <div className="right-table">
              <div style={{ padding: "0 16px" }}>
                {this.isSelectDom()}
                <span>
                  {ratioReportRespTimer ? ratioReportRespTimer.beginTime : ""}~
                  {ratioReportRespTimer ? ratioReportRespTimer.endTime : ""}
                </span>
              </div>
              <TableWrap pageData={ratioReportResp} />
            </div> */}
          </div>
        </Card>
        {/* 股票基金报表 */}
        <Card
          className="tabs-card-container"
          title="股票基金报表"
          extra={this.selectContent("equity")}
          style={{ marginTop: "15px" }}
        >
          <Radio.Group
            defaultValue={1}
            buttonStyle="solid"
            style={{ margin: "20px 0 0" }}
            onChange={this.equityRadioChange}
          >
            <Radio.Button value={1}>本月</Radio.Button>
            <Radio.Button value={2}>本年</Radio.Button>
            <Radio.Button value={3}>上月</Radio.Button>
            <Radio.Button value={4}>上年</Radio.Button>
          </Radio.Group>
          <Table
            loading={isLoading}
            rowKey={recode => recode.type}
            columns={columns}
            dataSource={equityTableData}
            pagination={false}
            style={{ marginTop: 20 }}
          />
        </Card>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  departmentData: state.userInfo.userInfo.chooseRespList,
  commonStore: state.commonStore
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(userActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
