import React, { useEffect, useRef, useState } from 'react'
import { Space, Tag, Button, Card, Row, Col, Input, Select, message, Popconfirm, Modal, Checkbox } from 'antd'
import { getAdminUserListApi, delAdminUserApi, relatedBranchApi, findBranchChooseApi } from '../../../../api/admin-users'
import EditUserModal from './modules/editUserModal'
import { connect } from 'react-redux'
import { getUserInfo } from '../../../../store/actions/user.action'
import TableWrap from '../../../../components/common/TableWrap/index'

const { Option } = Select

function User (props) {
    const [userList, setUserList] = useState([])
    const [isLoading, setisLoading] = useState(true)
    const [searchParams, setSearchParams] = useState({
        status: '',
        userName: ''
    })
    const [isRelatedShow, setisRelatedShow] = useState()
    const [userData, setuserData] = useState({})
    const [optionsCheck, setoptionsCheck] = useState([])
    const [currentSelect, setcurrentSelect] = useState()

    const [pageSize, setpageSize] = useState(10)
    const [currentPage, setcurrentPage] = useState(1)
    const [total, settotal] = useState(0)

    const modalRef = useRef()
    // 获取用户数据 生命周期函数
    useEffect(() => {
        getAdminUserList()
        getBranchChoose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, currentPage])

    // 表格数据结构
    const columns = [
        {
            title: 'ID',
            dataIndex: 'userId',
        },
        {
            title: '用户名称',
            dataIndex: 'userName',
        },
        {
            title: '角色',
            dataIndex: 'userRoleName',
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: status => (<Tag color={status ? 'green' : 'red'}>{status ? '正常' : '禁用'}</Tag>
            ),
        },
        {
            title: '关联部门',
            dataIndex: 'branchName'
        },
        {
            title: '操作',
            key: 'action',
            width: 100,
            render: (text, record) => (
                <Space size="middle">
                    <Button size='small' type="primary" onClick={() => modalRef.current.initFn(record)}>修改</Button>
                    <Popconfirm
                        title="是否要删除该用户?"
                        onConfirm={() => deleteUserById(record)}
                        onCancel={() => { }}
                        okText="确认"
                        cancelText="取消"
                    >
                        <Button size='small' type="primary" danger>删除</Button>
                    </Popconfirm>
                    <Button size='small' type="primary" onClick={() => { handleRelated(record) }} style={{ background: '#f6a000f5', borderColor: '#f6a000f5' }}>关联部门</Button>
                </Space>
            ),
        },
    ];
    // 查询事业部门选择框
    const getBranchChoose = () => {
        findBranchChooseApi().then(res => {
            if (res.code === 200) {
                let result = res.data.map(item => {
                    return {
                        label: item.branchName,
                        value: item.branchId
                    }
                })
                setoptionsCheck(result)
            }
        })
    }
    // 获取用户列表
    const getAdminUserList = (userName, status) => {
        const params = {
            data: {
                status,
                userId: 0,
                userName
            },
            pageNo: currentPage,
            pageSize: pageSize
        }
        getAdminUserListApi(params).then(res => {
            if (res.code === 200) {
                setUserList(res.data.list)
                settotal(res.data.total)
            }
            setisLoading(false)
        })
    }
    // 用户名查询
    const handleInputChange = (e) => {
        if (e.type === 'keyup' && e.keyCode === 13) {
            getAdminUserList(e.target.value, searchParams.status)
        }
        setSearchParams({ ...searchParams, userName: e.target.value })
    }
    // 状态筛选
    const handleChange = (value) => {
        let type = ''
        if (value === 1) {
            type = true
        } else if (value === 0) {
            type = false
        }
        getAdminUserList(searchParams.userName, type)
        setSearchParams({ ...searchParams, status: type })
    }
    // 搜索
    const handleSearch = (e) => {
        getAdminUserList(searchParams.userName, searchParams.status)
    }
    // 关联用户
    const handleRelated = record => {
        setisRelatedShow(true)
        setuserData(record)
        let newResult = []
        if (record.branchName) {
            record.branchName.split(',').forEach(cItem => {
                optionsCheck.forEach(item => {
                    if (item.label === cItem) {
                        newResult.push(item.value)
                    }
                })
            })
        }
        setcurrentSelect(newResult)
    }
    // 删除用户
    const deleteUserById = (record) => {
        delAdminUserApi({ userId: record.userId }).then(res => {
            if (res.code === 200) {
                message.success('删除成功')
                getAdminUserList()
            }
        })
    }
    // 事业部门选择回调
    const onCheckChange = (val) => {
        setcurrentSelect(val)
    }
    // 关联提交
    const handleSubmitFn = () => {
        relatedBranchApi({
            branchId: currentSelect,
            userId: userData.userId
        }).then(res => {
            if (res.code === 200) {
                message.success('操作成功')
                getAdminUserList()
                props.getUserInfo()
            } 
            setisRelatedShow(false)
        })
    }
    function onPageChange (current, size) {
        setpageSize(size)
        setcurrentPage(current)
    }
    return (
        <Card style={{ height: '100%' }}>
            <Row>
                <Col>
                    <Button type="primary" onClick={() => modalRef.current.initFn()}>新增</Button>
                </Col>
                <Col offset={1}>
                    <Input placeholder="输入用户名称查询" onChange={handleInputChange} onKeyUp={handleInputChange} allowClear />
                </Col>
                <Col offset={1}>
                    <Space size="middle">
                        <Select defaultValue={-1} style={{ width: 120 }} onChange={handleChange}>
                            <Option value={-1}>全部</Option>
                            <Option value={1}>正常</Option>
                            <Option value={0}>禁用</Option>
                        </Select>
                        <Button type="primary" onClick={handleSearch}>搜索</Button>
                    </Space>
                </Col>
            </Row>
            {/* <Table style={{ marginTop: 20 }} columns={columns} bordered dataSource={userList} loading={isLoading} rowKey={record => record.userId} /> */}
            <TableWrap
                rowKey={'userId'}
                columns={columns}
                pageList={userList}
                onPageChange={onPageChange}
                currentPage={currentPage}
                total={total}
                isLoading={isLoading}
                bordered
            />
            {/* 修改弹窗 */}
            <EditUserModal ref={modalRef} getData={getAdminUserList} getUserInfo={props.getUserInfo} />
            {/* 关联部门 */}
            <Modal title='关联部门' visible={isRelatedShow} footer={null} onCancel={() => setisRelatedShow(false)}>
                事业部门： <Checkbox.Group options={optionsCheck} value={currentSelect} style={{ margin: '10px' }} onChange={onCheckChange} />
                <div style={{ margin: '20px 0 10px', width: '100%', textAlign: 'right' }}>
                    <Space>
                        <Button onClick={() => setisRelatedShow(false)}>取消</Button>
                        <Button type="primary" onClick={handleSubmitFn}>确认</Button>
                    </Space>
                </div>
            </Modal>
        </Card>
    )
}

export default connect(
    state => ({}),
    {
        getUserInfo
    }
)(User)