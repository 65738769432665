import { POST } from '../config/server'

/* 查询对账单 */
export const findBillApi = params => POST('/bill/findBill', params)

// 查询账单分类选择框
export const findBillTypeApi = params => POST('/bill/findBillTypeChoose', params)

/* 导出对账单 */
export const exportBillApi = (params, headers) => POST('/bill/exportBill', params, headers)

/* 删除对账单数据 */
export const delBillApi = params => POST('/bill/delBill', params)

/* 编辑对账单 */
export const editBillApi = params => POST('/bill/editBill', params)

