import React, { useRef, useState } from 'react'
import { Layout, Button, Space, /* Select, */ Menu, Dropdown, Modal, Form, Input, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'
import './index.less'
import { connect } from 'react-redux';
import { logout } from '../../../store/actions/user.action'
import { refreshHomeData } from '../../../store/actions/common.action'

import { updatePassApi } from '../../../api/login';
import UploadModal from './modules/uploadModal'
import { isJurisdiction } from '../../../utils';
// import avatar from '../../../assets/images/logo-test.png'
const { Header } = Layout;
// const { Option } = Select

function HeaderWrap (props) {
    const { logout, userInfo } = props
    const [updatePassModal, setUpdatePassModal] = useState(false)
    const modalRef = useRef()
    // // 选择关联业务
    // const handleChange = (val) => {
    // }
    // 退出登录
    const handleMenuClick = (val) => {
        if (val.key === '2') {
            logout()
        } else if (val.key === '1') {
            setUpdatePassModal(true)
        }
    }
    // 修改密码
    const onFinish = (val) => {
        updatePassApi(val).then(res => {
            if (res.code === 200) {
                message.success('密码更改成功，请重新登录')
                localStorage.removeItem('Authorization')
                let timer = setTimeout(() => {
                    clearTimeout(timer)
                    window.location.href = window.location.origin + '/#/login'
                }, 2000)
            }
            setUpdatePassModal(false)
        })
    }
    const buttonDom = () => {
        if (isJurisdiction('/home/uploadExcel', props.userInfo.resourceList)) {
            return <Button type="primary" ghost onClick={() => modalRef.current.initFn()}>上传对账单</Button>
        }
    }
    // 下拉菜单数据展示
    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1">修改密码</Menu.Item>
            <Menu.Item key="2">退出登录</Menu.Item>
        </Menu>
    );
    return (
        <Header className="header-container">
            <div className="header-content">
                <Space>
                    {buttonDom()}
                    <Button type="primary" ghost><Link to="/bill" replace>对账单查询</Link></Button>
                </Space>
                <div className="right-info">
                    <div className="user">
                        {/* <img src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" alt="" /> */}
                        <Dropdown className="dropdown-menu" overlay={menu} trigger="click" style={{ padding: 10 }}>
                            <span className="ant-dropdown-link">
                                {userInfo.userName} <DownOutlined />
                            </span>
                        </Dropdown>
                    </div>
                </div>
            </div>
            {/* 上传对账单弹窗 */}
            <UploadModal ref={modalRef} options={props.refreshHomeData} />

            <Modal title="修改密码" visible={updatePassModal} footer={null} onCancel={() => setUpdatePassModal(false)}>
                <Form
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    {/* <Form.Item
                        label="旧密码"
                        name="oldPassword"
                        rules={[{ required: true, message: '请输入旧密码' }]}
                    >
                        <Input.Password style={{ width: 300 }} />
                    </Form.Item> */}

                    <Form.Item
                        label="新密码"
                        name="newPassword"
                        rules={[{ required: true, message: '请输入新密码' }]}
                    >
                        <Input.Password style={{ width: 300 }} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right', marginTop: 30 }}>
                        <Space>
                            <Button htmlType="button" onClick={() => setUpdatePassModal(false)}>
                                取消
                            </Button>
                            <Button type="primary" htmlType="submit">
                                确认
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </Header>
    )
}

export default connect(
    state => ({ userInfo: state.userInfo.userInfo }),
    {
        logout,
        refreshHomeData
    }
)(HeaderWrap)