import { GET, POST } from '../config/server'

/* 用户列表 增删改查 */
// 获取用户列表
export const getAdminUserListApi = params => POST('/admin/getAdminUserList', params)
// 新增
export const addAdminUserApi = params => POST('/admin/addAdminUser', params)
// 修改
export const updateAdminUserApi = params => POST('/admin/updateAdminUser', params)
// 删除
export const delAdminUserApi = params => GET('/admin/deleteAdminUser', params)
// 查询用户信息
export const getUserInfoByIdApi = params => GET('/admin/getUserInfoById', params)
// 关联部门
export const relatedBranchApi = params => POST('/admin/relatedBranch', params)
// 获取用户下拉框
export const getUserChooseApi = params => POST('/admin/getUserChoose', params)



/* 角色管理 增删改查 */
// 获取角色列表
export const getRoleListApi = params => POST('/role/getRoleList', params)
// 获取所有角色
export const getAllRoleApi = params => GET('/role/allRole', params)
// 新增
export const addRoleApi = params => POST('/role/addRole', params)
// 删除
export const delRoleApi = params => GET('/role/deleteRole', params)
// 修改
export const updateRoleApi = params => POST('/role/updateRole', params)
// 获取角色相关菜单
export const getRoleMenuApi = params => GET('/role/getRoleMenu', params)
// 获取角色相关资源
export const getRoleResourceApi = params => GET('/role/getRoleResource', params)
// 给角色分配菜单
export const addRoleMenuApi = params => POST('/role/addRoleMenu', params)
// 给角色分配资源
export const addRoleResourceApi = params => POST('/role/addRoleResource', params)


/* 菜单管理 增删改查 */
// 树形结构返回所有菜单列表
export const getTreeListApi = params => GET('/menu/treeList', params)
// 获取菜单列表
export const getMenuListApi = params => POST('/menu/getMenuList', params)
// 获取菜单信息
export const getMenuByIdApi = params => GET('/menu/getMenuById', params)
// 新增
export const addMenuApi = params => POST('/menu/addMenu', params)
// 修改
export const updateMenuApi = params => POST('/menu/updateMenu', params)
// 删除
export const delMenuApi = params => GET('/menu/deleteMenu', params)


/* 事业部门 */
// 查询事业部门列表
export const findBranchApi = params => GET('/branch/findBranch', params)
// 查询事业部门选择框
export const findBranchChooseApi = params => GET('/branch/findBranchChoose', params)
// 新增
export const addBranchApi = params => POST('/branch/addBranch', params)
// 编辑
export const editBranchApi = params => POST('/branch/editBranch', params)
// 删除
export const delBranchApi = params => GET('/branch/delBranch', params)
// 查询事业部门选择框根据用户ID
export const findBranchChooseByUserIdApi = params => GET('/branch/findBranchChooseByUserId', params)

/* 菜单资源配置 */
// 获取全部资源信息
export const getResourceTreeListApi = params => GET('/resource/getResourceTreeList', params)
// 新增
export const addResourceApi = params => POST('/resource/addResource', params)
// 编辑
export const updateResourceApi = params => POST('/resource/updateResource', params)
// 删除
export const deleteResourceApi = params => GET('/resource/deleteResource', params)

// 新增后台资源分类
export const addResourceCateApi = params => POST('/resourceCate/addResourceCate', params)
// 编辑
export const updateResourceCateApi = params => POST('/resourceCate/updateResourceCate', params)
// 删除
export const deleteResourceCateApi = params => GET('/resourceCate/deleteResourceCate', params)



/* 系统日志 */
// 系统操作日志列表
export const findPageListApi = params => POST('/sysLog/findPageList', params)
export const getCopyrightLogoApi = params => POST('/sysLog/getCopyrightLogo', params)
export const setCopyrightLogoApi = params => POST('/sysLog/setCopyrightLogo', params)



/* 银行账户 */
// 查询银行账户列表
export const findBankAccountPageApi = params => POST('/bank/findBankAccountPage', params)
// 新增银行账户
export const addBankAccountApi = params => POST('/bank/addBankAccount', params)
// 编辑银行账户
export const editBankAccountApi = params => POST('/bank/editBankAccount', params)
// 编辑银行账户状态
export const editBankAccountStatusApi = params => POST('/bank/editBankAccountStatus', params)
// 查询银行账户选择框
export const findBankChooseApi = params => POST('/bank/findBankChoose', params)










