import { POST } from '../config/server'

/* 下载模板 */
export const downloadTemplateApi = (params, header) => POST('/home/downloadTemplate', params, header)
// 查询账单上传详情
export const billUploadDetailsApi = params => POST('/home/billUploadDetails', params)
// 查询主页数据分析
export const findDataAnalysisApi = params => POST('/home/findDataAnalysis', params)
// 上传对账单Excel
export const uploadExcelApi = params => POST('/home/uploadExcel', params)

// 查询分类占比报表
export const findTypeRatioReportApi = params => POST('/home/findTypeRatioReport', params)

// 查询股票基金报表
export const findEquityFundsReportApi = params => POST('/home/findEquityFundsReport', params)

/* 查询主页收入回款数据分析 */
export const findRevenueCollectionDataAnalysisApi = params => POST('/home/findRevenueCollectionDataAnalysis', params)


