import React, { /* useEffect, useState */ } from 'react'
import { Layout } from 'antd';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import Home from '../../../pages/admin/home'
import Bill from '../../../pages/admin/bill/list'
import Report from '../../../pages/admin/report'
import User from '../../../pages/admin/setting/user'
import Role from '../../../pages/admin/setting/role'
import MenuList from '../../../pages/admin/setting/menu'
import Department from '../../../pages/admin/setting/department'
import Journal from '../../../pages/admin/setting/journal'
import Resources from '../../../pages/admin/setting/resources'
import Bank from '../../../pages/admin/setting/bank'

const { Content } = Layout;
function ContentWrap (props) {
    /* const [menuList, setmenuList] = useState([])
    useEffect(() => {
        let menuArr = props.userInfo.adminMenuRespDtoList ? props.userInfo.adminMenuRespDtoList : []
        setmenuList(setMenuTree(menuArr))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const setMenuTree = menu => {
        return menu.map(item => {
            if (item.adminMenuNodeRespDtoList.length === 0 || item.parentMenuId === 0) {
                return {
                    title: item.menuName,
                    key: item.menuPath,
                    icon: item.icon,
                    children: setMenuTree(item.adminMenuNodeRespDtoList).length > 0 ? setMenuTree(item.adminMenuNodeRespDtoList) : null
                }
            } else {
                return []
            }
        })
    }
    let routerArr = []
    function routerFn (menu) {
        menu.forEach(item => {
            if (item.children && item.children.length > 0) {
                routerFn(item.children)
            } else {
                routerArr.push(item)
            }
        })
        return routerArr
    }
    const pathI = routerFn(menuList).findIndex(item => props.location.pathname === item.key)
     */
    // if (!pathI || pathI < 0) {
    //     return (
    //         <Content style={{ margin: '16px', overflow: 'initial', minHeight: '70vh', marginTop: 80 }}>
    //             <Switch>
    //                 <Route path='/home' component={Home} />
    //                 <Redirect to='/home' />
    //             </Switch>
    //         </Content>
    //     )
    // } else {
    return (
        <Content style={{ margin: '16px', overflow: 'initial', minHeight: '70vh', marginTop: 80 }}>
            <Switch>
                <Route path='/home' component={Home} />
                <Route path='/bill' component={Bill} />
                <Route path='/report' component={Report} />
                <Route path='/setting/user' component={User} />
                <Route path='/setting/role' component={Role} />
                <Route path='/setting/menu' component={MenuList} />
                <Route path='/setting/department' component={Department} />
                <Route path='/setting/journal' component={Journal} />
                <Route path='/setting/resources' component={Resources} />
                <Route path='/setting/bank' component={Bank} />
                <Redirect to='/home' />
            </Switch>
        </Content>
    )
    // }
}
export default connect(
    state => ({ userInfo: state.userInfo.userInfo })
)(withRouter(ContentWrap))