import React, { useState, useEffect } from 'react'
import { Table } from 'antd';
import { formatMoney } from '../../../../utils'

export default function TableWrap (props) {
    const [tabData, settabData] = useState([]);
    useEffect(() => {
        if (props.pageData) {
            let timer = setTimeout(() => {
                clearTimeout(timer)
                settabData(props.pageData.reportDto)
            })
        }
    }, [props])
    const columns = [
        {
            title: '分类',
            dataIndex: 'type',
            width: 100
        },
        {
            title: '收入',
            dataIndex: 'income',
            minWidth: 100,
            render: text => <span>{text ? `${formatMoney(text / 10000, 0)}万` : '--'}</span>
        },
        {
            title: '支出',
            dataIndex: 'expenditure',
            minWidth: 100,
            render: text => <span>{text ? `${formatMoney(text / 10000, 0)}万` : '--'}</span>
        },
        {
            title: '收入占比',
            dataIndex: 'incomeRatio',
            render: text => <span>{text ? text.toFixed(2) + '%' : '--'}</span>
        },
        {
            title: '支出占比',
            dataIndex: 'expenditureRatio',
            render: text => <span>{text ? text.toFixed(2) + '%' : '--'}</span>
        },
    ];
    return (
        <Table
            rowKey={record => record.type}
            columns={columns}
            dataSource={tabData}
            style={{ height: 380, background: '#f8f8f8' }}
            pagination={false} scroll={{ y: 330 }}
        />
    )
}

