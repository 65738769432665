import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Modal, Button, Upload, message } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons'
import { downloadTemplateApi } from '../../../../api/home';
import { BSAE_URL } from '../../../../config/baseUrl'
import { excelReport } from '../../../../utils'
import './index.less'

const { Dragger } = Upload

const UploadModal = (props, ref) => {
    // 弹窗状态管理
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setisLoading] = useState(false)
    const params = {
        name: 'file',
        accept: ".xls,.xlsx",
        multiple: false,
        showUploadList: false,
        action: `${BSAE_URL}/home/uploadExcel`,
        headers: {
            Authorization: localStorage.getItem('Authorization')
        },
        // 上传文件前的钩子函数
        beforeUpload (file) {
            message.loading('正在上传中...');
            return true;
        },
        async onChange (info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                if (info.file.response.code !== 200) {
                    message.destroy();
                    message.error(info.file.response.msg);
                } else {
                    message.destroy();
                    message.success('导入成功');
                    props.options()
                }
                setIsModalVisible(false)
            } else if (status === 'error') {
                message.error(`${info.file.name}上传失败`);
            }
        }
    };
    useImperativeHandle(ref, () => {
        return {
            initFn: () => {
                setIsModalVisible(true)
            }
        }
    })
    // 下载模板
    const handleDownload = () => {
        setisLoading(true)
        downloadTemplateApi({}, { responseType: 'blob' }).then(res => {
            setisLoading(false)
            excelReport(res, '资金日报模板')
        })
    }
    return (
        <Modal className="upload-modal" title="上传对账单" visible={isModalVisible} footer={null} onCancel={() => setIsModalVisible(false)}>
            <div className="upload-container">
                <Button loading={isLoading} type='link' onClick={handleDownload}>下载标准现金模板</Button>
                <div className="upload-box">
                    <Dragger {...params}>
                        <p className="ant-upload-drag-icon">
                            <CloudUploadOutlined style={{ color: '#ccc' }} />
                        </p>
                        <p className="ant-upload-text">请将您的对账单文件拖入此处，或<Button type="link">选择对账单文件</Button></p>
                    </Dragger>
                </div>
            </div>
        </Modal>
    );
}

export default forwardRef(UploadModal);
