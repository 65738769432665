import React, { useEffect, useState, useRef } from 'react';
import { Card, Table, Button, Modal, Space, Form, Input, Radio, message, Popconfirm, Tag } from 'antd'
import { addResourceCateApi, deleteResourceApi, deleteResourceCateApi, getResourceTreeListApi, updateResourceCateApi } from '../../../../api/admin-users';
import EditModal from './modules/editModal'

const Resources = () => {
    const [resourceTreeList, setresourceTreeList] = useState([]);
    const [isLoading, setisLoading] = useState(true)
    const [isVisible, setisVisible] = useState(false)
    const [modalType, setmodalType] = useState('add')
    const [formData, setformData] = useState({})
    const modalRef = useRef()
    const formRef = useRef()
    useEffect(() => {
        getResourceTreeList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const columns = [
        {
            title: '分类名称',
            dataIndex: 'cateName',
            key: 'cateName'
        },
        {
            title: '排序',
            dataIndex: 'sort',
        },
        {
            title: '资源名称',
            dataIndex: 'sourceName',
        },
        {
            title: '资源URL',
            dataIndex: 'sourceUrl',
        },
        {
            title: '描述',
            dataIndex: 'sourceDesc',
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: '100px',
            render: text => {
                if (text === undefined) {
                    return {}
                } else {
                    return (<span><Tag color={text ? 'green' : 'red'}>{text ? '正常' : '禁止'}</Tag></span>)
                }
            }
        },
        {
            title: '操作',
            dataIndex: 'actions',
            width: 120,
            render: (text, record) => {
                return (
                    <div>
                        <Space>
                            <Button size="small" type="primary" onClick={() => openChangeModal('edit', record)}>编辑</Button>
                            <Popconfirm
                                title="是否要删除该资源?"
                                onConfirm={() => deleteResourceCate(record)}
                                onCancel={() => { }}
                                okText="确认"
                                cancelText="取消">
                                <Button size="small" type="primary" danger>删除</Button>
                            </Popconfirm>

                        </Space >
                    </div >
                )
            }
        }
    ]
    const options = [
        {
            label: '开启',
            value: 1
        },
        {
            label: '关闭',
            value: 0
        }
    ]
    const getResourceTreeList = () => {
        getResourceTreeListApi({ status: -99 }).then(res => {
            setisLoading(false)
            if (res.code === 200) {
                setresourceTreeList(setMenuTree(res.data))
            }
        })
    }
    const setMenuTree = menu => {
        if (menu && menu.length >= 0) {
            return menu.map((item, index) => {
                return {
                    cateId: item.cateId,
                    cateName: item.cateName,
                    sourceDesc: item.sourceDesc,
                    sourceName: item.sourceName,
                    sourceUrl: item.sourceUrl,
                    rowKey: index,
                    sort: item.sort,
                    status: item.status,
                    sourceId: item.sourceId,
                    children: setMenuTree(item.adminResourceRespDtoList) && setMenuTree(item.adminResourceRespDtoList).length > 0 ? setMenuTree(item.adminResourceRespDtoList) : null
                }
            })

        }
    }
    // 资源类添加提交
    const submitResourceCateFn = async (val) => {
        let res
        if (modalType === 'add') {
            res = await addResourceCateApi({ ...val, status: val.status === 1 })
        } else {
            res = await updateResourceCateApi({ ...val, cateId: formData.cateId, status: val.status === 1 })
        }
        if (res.code === 200) {
            message.success('操作成功')
            setisVisible(false)
            getResourceTreeList()
        }
    }
    const openChangeModal = async (type, data) => {
        if (data && data.sourceId) {
            modalRef.current.initFn(data)
        } else {
            setisVisible(true)
            setmodalType(type)
            let timer = setTimeout(() => {
                clearTimeout(timer)
                setformData(data)
                if (data) {
                    formRef.current.setFieldsValue({ ...data, status: data.status ? 1 : 0 })
                } else {
                    formRef.current.resetFields()
                }
            })
        }
    }
    const deleteResourceCate = record => {
        if (record.sourceId) {
            deleteResourceApi({ sourceId: record.sourceId }).then(res => {
                if (res.code === 200) {
                    message.success('删除成功')
                    getResourceTreeList()
                }
            })
        } else {
            deleteResourceCateApi({ cateId: record.cateId }).then(res => {
                if (res.code === 200) {
                    message.success('删除成功')
                    getResourceTreeList()
                }
            })
        }
    }
    return (
        <Card style={{ height: '100%' }}>
            <Space size="middle">
                <Button type="primary" onClick={() => openChangeModal('add')}>新增分类</Button>
                <Button type="primary" onClick={() => modalRef.current.initFn()}>新增资源</Button>
            </Space>
            <Table
                rowKey={record => record.rowKey}
                columns={columns}
                dataSource={resourceTreeList}
                loading={isLoading}
                style={{ marginTop: 10 }}
                bordered
            />
            <EditModal ref={modalRef} resourceTreeList={resourceTreeList} getData={getResourceTreeList} />
            <Modal title="添加" visible={isVisible} footer={null} onCancel={() => { setisVisible(false) }}>
                <Form ref={formRef} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} initialValues={{ status: 1 }} onFinish={submitResourceCateFn}>
                    <Form.Item name="cateName" label="分类名称" rules={[{ required: true, message: '请输入分类名称' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="sort" label="排序">
                        <Input />
                    </Form.Item>
                    <Form.Item name="status" label="状态">
                        <Radio.Group
                            options={options}
                            value={1}
                        />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right' }}>
                        <Space size="middle">
                            <Button onClick={() => setisVisible(false)}>取消</Button>
                            <Button type="primary" htmlType="submit">确定</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </Card>
    );
}

export default Resources;
