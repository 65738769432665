import React, { useEffect, useState } from "react";
import { Card, Space, Button, Input, Popover } from "antd";
import { findPageListApi } from "../../../../api/admin-users";
import TableWrap from "../../../../components/common/TableWrap/index";

const Journal = () => {
  const [pageList, setpageList] = useState([]);
  const [searchUsername, setSearchUsername] = useState();
  const [pageSize, setpageSize] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [total, settotal] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [searchParams, setsearchParams] = useState();
  const columns = [
    {
      title: "用户名",
      dataIndex: "username"
    },
    {
      title: "IP地址",
      dataIndex: "ip"
    },
    // {
    //     title: '请求方法',
    //     dataIndex: 'method',
    //     textWrap: 'word-break',
    //     ellipsis: true,
    //     width: 300
    // },
    {
      title: "用户操作",
      dataIndex: "operation"
    },
    {
      title: "请求参数",
      dataIndex: "params",
      textWrap: "word-break",
      ellipsis: true,
      width: 150,
      render: text => {
        return (
          <Popover placement="topLeft" content={text} trigger="hover">
            <span style={{ cursor: "pointer" }}>{text}</span>
          </Popover>
        );
      }
    },
    // {
    //     title: '执行时长(毫秒)',
    //     dataIndex: 'time',
    // },
    {
      title: "创建时间",
      dataIndex: "createDate"
    }
  ];
  useEffect(() => {
    getPageListData(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, currentPage]);
  const getPageListData = page => {
    const params = {
      data: {
        userName: searchUsername,
        keyWord: searchParams
      },
      pageNo: page,
      pageSize: pageSize
    };
    setisLoading(true);
    findPageListApi({ ...params }).then(res => {
      setisLoading(false);
      if (res.code === 200) {
        setpageList(res.data.list);
        settotal(res.data.total);
      }
    });
  };
  // 用户名查询
  const handleUsername = e => {
    setSearchUsername(e.target.value.replace(/\s+/g, ""));
    if (e.type === "keyup" && e.keyCode === 13) {
      getPageListData(currentPage);
    }
  };
  const handleInputChange = e => {
    setsearchParams(e.target.value.replace(/\s+/g, ""));
    if (e.type === "keyup" && e.keyCode === 13) {
      getPageListData(currentPage);
    }
  };
  function onPageChange(current, size) {
    setpageSize(size);
    setcurrentPage(current);
  }
  function searchHandle() {
    setcurrentPage(1);
    getPageListData(1);
  }
  return (
    <Card style={{ height: "100%" }}>
      <Space>
        <Input
          placeholder="用户名查询"
          onChange={handleUsername}
          onKeyUp={handleUsername}
          allowClear
          value={searchUsername}
        />
        <Input
          placeholder="用户操作"
          onChange={handleInputChange}
          onKeyUp={handleInputChange}
          allowClear
          value={searchParams}
        />
        <Button onClick={searchHandle}>搜索</Button>
      </Space>
      <TableWrap
        rowKey={"id"}
        columns={columns}
        pageList={pageList}
        onPageChange={onPageChange}
        currentPage={currentPage}
        total={total}
        isLoading={isLoading}
        bordered
      />
    </Card>
  );
};

export default Journal;
