import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Select, DatePicker, Space, Button, message } from "antd";
import { TableOutlined } from "@ant-design/icons";
import "./index.less";
import {
  dailyMoneyReportApi,
  moneyDetailsReportApi,
  exportBranchDayReportApi
} from "../../../api/report";
import moment from "moment";
import { excelReport, isJurisdiction } from "../../../utils";
import ViewModal from "./modules/viewModal";
const { RangePicker } = DatePicker;
class Report extends Component {
  state = {
    dayBranchId: this.props.departmentData
      ? this.props.departmentData.map(item => item.branchId)
      : [],
    dayDate: moment(new Date()).format("YYYY-MM-DD"),
    incomeBranchId: this.props.departmentData
      ? this.props.departmentData.map(item => item.branchId)
      : [],
    incomeDate: [],
    branchDate: [],
    branchDayId: this.props.departmentData
      ? this.props.departmentData.map(item => item.branchId)
      : [],
    dayIsLoading: false,
    incomeIsLoading: false,
    branchIsLoading: false,
    viewRef: React.createRef(null)
  };
  // 选择时间
  onChange = (val, type) => {
    if (type === "capital") {
      console.log(val, "日报表时间");
      this.setState({ dayDate: val ? moment(val).format("YYYY-MM-DD") : null });
    } else if (type === "income") {
      this.setState({ incomeDate: val });
    } else if (type === "branch") {
      this.setState({
        branchDate: val
      });
    }
  };
  downExpend = async (type, params) => {
    const { dayBranchId, incomeBranchId, dayDate, incomeDate, branchDate, branchDayId } = this.state;
    let title = "";
    let res;
    if (type === "capital") {
      if (dayBranchId.length === 0)
        return message.warning({ content: "请选择事业部", key: "branchId" });
      if (!params.time)
        return message.warning({ content: "请选择日期", key: "capital" });
      title = `${this.titleHandle(dayBranchId)} ${dayDate}至${
        params.endTime
      }账户日报表`;
      this.setState({ dayIsLoading: true });
      res = await dailyMoneyReportApi(
        { branchId: dayBranchId, ...params },
        { responseType: "blob" }
      );
      this.setState({ dayIsLoading: false });
    } else if (type === "income") {
      if (incomeBranchId.length === 0)
        return message.warning({ content: "请选择事业部", key: "branchId" });
      if (!incomeDate || incomeDate.length < 2)
        return message.warning({ content: "请选择日期", key: "income" });
      title = `${this.titleHandle(incomeBranchId)} ${params.date}收入回款表`;
      this.setState({ incomeIsLoading: true });
      res = await moneyDetailsReportApi(
        { branchId: incomeBranchId, ...params },
        { responseType: "blob" }
      );
      this.setState({ incomeIsLoading: false });
    } else if (type === "branch") {
      if (branchDayId.length === 0)
        return message.warning({ content: "请选择事业部", key: "branchId" });
      if (!branchDate || branchDate.length < 2)
        return message.warning({ content: "请选择日期", key: "income" });
        console.log(params, "params")
      title = `${this.titleHandle(branchDayId)} ${params.beginTime}~${params.endTime}部门日报表`;
      this.setState({ branchIsLoading: true });
      res = await exportBranchDayReportApi(
        { branchId: branchDayId, ...params },
        { responseType: "blob" }
      );
      this.setState({ branchIsLoading: false });
    }
    excelReport(res, title);
  };
  titleHandle = arr => {
    let title = "";
    this.props.departmentData.forEach(item => {
      arr.forEach(rItem => {
        if (item.branchId === rItem) {
          title += `、${item.branchName}`;
        }
      });
    });
    return title.substr(1);
  };
  buttonDom1 = () => {
    const { dayDate, dayIsLoading } = this.state;
    if (
      isJurisdiction(
        "/report/dailyMoneyReport",
        this.props.userInfo.resourceList
      )
    ) {
      return (
        <Button
          loading={dayIsLoading}
          type="link"
          onClick={() =>
            this.downExpend("capital", {
              time: dayDate
            })
          }
        >
          导出
        </Button>
      );
    }
  };
  buttonDom2 = () => {
    const { incomeDate, incomeIsLoading } = this.state;
    if (
      isJurisdiction(
        "/report/moneyDetailsReport",
        this.props.userInfo.resourceList
      )
    ) {
      return (
        <Button
          type="link"
          loading={incomeIsLoading}
          onClick={() =>
            this.downExpend("income", {
              beginTime: moment(incomeDate[0]).format("YYYY-MM-DD"),
              endTime: moment(incomeDate[1]).format("YYYY-MM-DD")
            })
          }
        >
          导出
        </Button>
      );
    }
  };
  buttonDom3 = () => {
    const { branchDate, branchIsLoading } = this.state;
    if (
      isJurisdiction(
        "/report/exportBranchDayReport",
        this.props.userInfo.resourceList
      )
    ) {
      return (
        <Button
          type="link"
          loading={branchIsLoading}
          onClick={() =>
            this.downExpend("branch", {
              beginTime: moment(branchDate[0]).format("YYYY-MM-DD"),
              endTime: moment(branchDate[1]).format("YYYY-MM-DD")
            })
          }
        >
          导出
        </Button>
      );
    }
  };
  //   查看
  handleViewFn = type => {
    const {
      viewRef,
      branchDayId,
      branchDate,
      dayBranchId,
      dayDate,
      incomeBranchId,
      incomeDate
    } = this.state;
    if (type === "capital") {
      viewRef.current.initFn({
        type: "capital",
        branchId: dayBranchId,
        time: dayDate
      });
    } else if (type === "income") {
      viewRef.current.initFn({
        type: "income",
        branchId: incomeBranchId,
        date: incomeDate
      });
    } else if (type === "branch") {
      viewRef.current.initFn({
        type: "branch",
        branchId: branchDayId,
        date: branchDate
      });
    }
  };
  // 部门选择
  selectContent = type => {
    if (this.props.departmentData) {
      const options = this.props.departmentData.map(item => ({
        label: item.branchName,
        value: item.branchId
      }));
      let branchId;
      if (type === "capital") {
        branchId = this.state.dayBranchId;
      } else if (type === "income") {
        branchId = this.state.incomeBranchId;
      } else if (type === "branch") {
        branchId = this.state.branchDayId;
      }
      return (
        <Select
          mode="multiple"
          style={{ width: "220px" }}
          value={branchId}
          options={options}
          onChange={e => this.selectChange(e, type)}
          maxTagCount="responsive"
          placeholder="请选择部门"
          dropdownRender={menu => (
            <div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  textAlign: "center",
                  padding: 8,
                  cursor: "pointer"
                }}
              >
                <span
                  style={{ flex: 1 }}
                  onClick={() => this.allSelectHandle(type)}
                >
                  全选
                </span>
                <span
                  style={{ flex: 1 }}
                  onClick={() => this.unSelectHandle(type)}
                >
                  反选
                </span>
              </div>
              {menu}
            </div>
          )}
        />
      );
    }
  };
  // 部门多选查看收支明细
  selectChange = (val, type) => {
    // if (val.length > 0) {
    if (type === "capital") {
      this.setState({
        dayBranchId: val
      });
    } else if (type === "income") {
      this.setState({
        incomeBranchId: val
      });
    } else if (type === "branch") {
      this.setState({
        branchDayId: val
      });
    }
    // }
  };
  // 全选
  allSelectHandle = type => {
    if (type === "capital") {
      this.setState({
        dayBranchId: this.props.departmentData
          ? this.props.departmentData.map(item => item.branchId)
          : []
      });
    } else if (type === "income") {
      this.setState({
        incomeBranchId: this.props.departmentData
          ? this.props.departmentData.map(item => item.branchId)
          : []
      });
    } else if (type === "branch") {
      this.setState({
        branchDayId: this.props.departmentData
          ? this.props.departmentData.map(item => item.branchId)
          : []
      });
    }
  };
  // 反选
  unSelectHandle = type => {
    const { dayBranchId, incomeBranchId, branchDayId } = this.state;
    if (type === "capital") {
      const branchIdArr =
        this.noSelect(dayBranchId).length === 0
          ? [this.props.departmentData[0].branchId]
          : this.noSelect(dayBranchId);
      this.setState({
        dayBranchId: branchIdArr
      });
    } else if (type === "income") {
      this.setState({
        incomeBranchId:
          this.noSelect(incomeBranchId).length === 0
            ? [this.props.departmentData[0].branchId]
            : this.noSelect(incomeBranchId)
      });
    } else if (type === "branch") {
      this.setState({
        branchDayId:
          this.noSelect(branchDayId).length === 0
            ? [this.props.departmentData[0].branchId]
            : this.noSelect(branchDayId)
      });
    }
  };
  noSelect = arr => {
    let newArr = [];
    this.props.departmentData.forEach(item => {
      let it = arr.findIndex(rI => rI === item.branchId);
      if (it < 0) {
        newArr.push(item.branchId);
      }
    });
    return newArr;
  };
  render() {
    return (
      <Card style={{ height: "100%" }}>
        <div className="income-expend">
          <div className="item">
            <div className="title">
              <span>
                <TableOutlined />
                <br /> 账户日报表
              </span>
              <Space className="action-btn" size="small">
                <span onClick={() => this.handleViewFn("capital")}>查看</span>
                {this.buttonDom1()}
              </Space>
            </div>
            <div className="describe">查看指定事业部在某天的流水统计</div>
            <Space>
              {this.selectContent("capital")}
              <DatePicker
                defaultValue={moment(new Date(), "YYYY-MM-DD")}
                format="YYYY-MM-DD"
                onChange={val => {
                  this.onChange(val, "capital");
                }}
              />
            </Space>
          </div>

          <div className="item">
            <div className="title">
              <span>
                <TableOutlined />
                <br />
                收入回款表
              </span>
              <Space className="action-btn" size="small">
                <span onClick={() => this.handleViewFn("income")}>查看</span>
                {this.buttonDom2()}
              </Space>
            </div>
            <div className="describe">
              查看指定部门在一定时间范围内的收入统计
            </div>
            <Space>
              {this.selectContent("income")}
              <RangePicker
                format="YYYY-MM-DD"
                onChange={val => {
                  this.onChange(val, "income");
                }}
              />
            </Space>
          </div>
          <div className="item">
            <div className="title">
              <span>
                <TableOutlined />
                <br />
                部门日报表
              </span>
              <Space className="action-btn" size="small">
                <span onClick={() => this.handleViewFn("branch")}>查看</span>
                {this.buttonDom3()}
              </Space>
            </div>
            <div className="describe">
              查看指定部门在一定时间范围内的流水统计
            </div>
            <Space>
              {this.selectContent("branch")}
              <RangePicker
                format="YYYY-MM-DD"
                onChange={val => {
                  this.onChange(val, "branch");
                }}
              />
            </Space>
          </div>
        </div>
        <ViewModal ref={this.state.viewRef} userInfo={this.props.userInfo} />
      </Card>
    );
  }
}

export default connect(state => ({
  departmentData: state.userInfo.userInfo.chooseRespList,
  userInfo: state.userInfo.userInfo
}))(Report);
