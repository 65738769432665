import { createAction } from 'redux-actions'

// 登录
export const login = createAction('login')
export const getUserInfo = createAction('getUserInfo')
// 记录用户token
export const saveUserToken = createAction('saveUserToken')
// 存储用户信息
export const saveUserInfo = createAction('saveUserInfo')
// 退出登录
export const logout = createAction('logout')
// 清空用户数据
export const clearUserInfo = createAction('clearUserInfo')