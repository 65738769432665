import React from 'react'
import { Switch, Route} from 'react-router-dom'
import Login from './pages/login'
import Admin from './pages/admin/admin'
import Page404 from './pages/404/index'
import './assets/css/common.less'

export default function App () {
    return (
        <Switch>
            <Route path="/404" component={ Page404 }/>
            <Route path="/login" component={ Login }/>
            <Route path="/" component={ Admin }/>
        </Switch>
    )
}