import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Space,
  Popconfirm,
  message
} from "antd";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/zh-cn";
import { exportBillApi, delBillApi } from "../../../api/bill";
import { excelReport, isJurisdiction } from "../../../utils";
import { findBankChooseApi, getUserChooseApi } from "../../../api/admin-users";
import "./search.less";

moment.locale("zh-cn");
const { Option } = Select;
const { RangePicker } = DatePicker;

const SearchTemplate = props => {
  const [form] = Form.useForm();
  const [paramsData, setparamsData] = useState({
    bankAccount: "",
    beginBillTime: "",
    endBillTime: "",
    memo: "",
    summary: "",
    type: ""
  });
  // 加载loading
  const [isLoading, setisLoading] = useState(false);
  // 银行账户信息
  const [userList, setuserList] = useState([]);
  // 开始时间
  const [startTime, setStartTime] = useState(moment().format("YYYY-MM-DD"));
  // 选择当前部门
  const [currentBranchId, setCurrentBranchId] = useState({
    branchName: "",
    branchId: null
  });
  // 是否显示确认弹窗
  const [visible, setVisible] = useState(false);
  // 获取用户信息
  const [selectUser, setSelectUser] = useState([]);
  // 选择当前银行账户
  const [currentAccountId, setCurrentAccountId] = useState(null);
  const [accountName, setAccountName] = useState("");
  // 生命周期
  useEffect(() => {
    findBankChooseApi().then(res => {
      if (res.code === 200) {
        setuserList(res.data.map(item => item));
      }
    });
    // 获取用户信息
    getUserChooseApi().then(res => {
      setSelectUser(res);
    });
  }, []);
  // 表单提交
  const onFinish = val => {
    let params = {
      ...val,
      branchId: val.branchId === -1 ? "" : val.branchId
    };
    if (val.selectTime && val.selectTime.length > 0) {
      params = {
        ...params,
        beginBillTime: moment(val.selectTime[0]).format("YYYY-MM-DD"),
        endBillTime: moment(val.selectTime[1]).format("YYYY-MM-DD")
      };
    }
    delete params.selectTime;
    params = {
      ...params,
      type: params.type === -1 ? "" : params.type,
      bankAccount: params.bankAccount === -1 ? "" : params.bankAccount
    };
    setparamsData(params);
    props.getData(params);
  };
  // 选择部门回调
  const onGenderChange = val => {
    if (val > 0) {
      let obj = props.departmentData.find(item => item.branchId === val);
      setCurrentBranchId(obj);
    } else {
      setCurrentBranchId({
        branchName: "",
        branchId: ""
      });
    }
  };
  // 重置搜索条件
  const onReset = () => {
    setCurrentBranchId({
      branchName: "",
      branchId: ""
    });
    form.resetFields();
    props.getData({});
  };
  // 导出数据
  const handleExport = async () => {
    let params = form.getFieldsValue();
    if (params.selectTime && params.selectTime.length > 1) {
      params = {
        ...params,
        beginBillTime: moment(params.selectTime[0]).format("YYYY-MM-DD"),
        endBillTime: moment(params.selectTime[1]).format("YYYY-MM-DD")
      };
    }
    params = {
      ...params,
      type: params.type === -1 ? "" : params.type,
      bankAccount: params.bankAccount === -1 ? "" : params.bankAccount,
      branchId: params.branchId === -1 ? "" : params.branchId
    };
    delete params.selectTime;
    if (!isLoading) {
      setisLoading(true);
      let res = await exportBillApi(params, { responseType: "blob" });
      setisLoading(false);
      excelReport(res, "对账单");
    }
  };
  // 导出权限 显示隐藏
  const buttonDom = () => {
    if (isJurisdiction("/bill/exportBill", props.userInfo.resourceList)) {
      return (
        <Button onClick={handleExport} loading={isLoading}>
          导出
        </Button>
      );
    }
  };
  // 公用下拉选择器
  function selectDom(type) {
    let result;
    if (type === "type") {
      result = props.selectData;
    } else {
      result = userList;
      if (type == "bankAccount") {
        result = userList.map(item => item.bankAccount);
      }
    }
    return (
      <Select
        allowClear
        showSearch
        style={{ width: 200 }}
        placeholder={type === "type" ? "选择分类" : "输入账户名查询"}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children
            .toLowerCase()
            .indexOf(input.replace(/\s+/g, "").toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
        onClear={() => {
          onClearHandle(type);
        }}
        onChange={userOnChange}
      >
        <Option value={-1}>全部</Option>
        {result.map(item => {
          return (
            <Option key={item} value={item}>
              {item}
            </Option>
          );
        })}
      </Select>
    );
  }
  // 选择银行账户
  function userOnChange(val) {
    console.log(val, "val", userList);
    let accountId = userList.find(item => item.bankAccount === val)
      ? userList.find(item => item.bankAccount === val).accountId
      : 0;
    setCurrentAccountId(accountId ? accountId : null);
    setAccountName(val);
  }

  function onClearHandle(type) {
    let timer = setTimeout(() => {
      clearTimeout(timer);
      if (type === "type") {
        form.setFieldsValue({ type: -1 });
      } else if (type === "bankAccount") {
        form.setFieldsValue({ bankAccount: -1 });
      } else {
        form.setFieldsValue({ branchId: -1 });
      }
    });
  }
  function onTimeChange(val) {
    if (val && val.length > 1) {
      setStartTime(moment(val[0]).format("YYYY-MM-DD"));
    }
  }
  function handleVisibleChange() {
    // if (currentBranchId.branchId) {
    setVisible(true);
    // } else {
    //   message.warning({
    //     content: "请选择部门进行删除",
    //     key: "branchId"
    //   });
    //   setVisible(false);
    // }
  }
  function delBillHandle() {
    message.loading("正在删除...");
    delBillApi({
      beginBillTime: startTime,
      branchId: currentBranchId.branchId,
      accountId: currentAccountId
    }).then(res => {
      message.destroy();
      if (res && res.code === 200) {
        message.success("删除成功");
        props.getData(paramsData);
      } else {
        message.error({
          content: res.msg || "删除失败",
          key: res.code || "delete"
        });
      }
      setVisible(false);
    });
  }
  function handleChange(val) {
    console.log(val);
  }
  return (
    <div className="search-template">
      <Form
        form={form}
        name="control-hooks"
        initialValues={{ branchId: -1, type: -1, bankAccount: -1 }}
        onFinish={onFinish}
      >
        <Row>
          <Col offset={1}>
            <Form.Item name="selectTime" label="起止日期">
              <RangePicker format="YYYY-MM-DD" onChange={onTimeChange} />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <div style={{ display: "flex", alignItems: "" }}>
              <Form.Item
                name="bigMoney"
                label="余额"
                getValueFromEvent={event =>
                  event.target.value.replace(/\s+/g, "")
                }
              >
                <Input style={{ width: 120 }} placeholder="最小值" />
              </Form.Item>
              <span style={{ padding: "0 10px", paddingTop: 5 }}> - </span>
              <Form.Item
                name="endMoney"
                getValueFromEvent={event =>
                  event.target.value.replace(/\s+/g, "")
                }
              >
                <Input style={{ width: 120 }} placeholder="最大值" />
              </Form.Item>
            </div>
          </Col>
          <Col offset={1}>
            <Form.Item name="branchId" label="部门选择">
              <Select
                allowClear
                placeholder="选择部门"
                onChange={onGenderChange}
                onClear={() => {
                  onClearHandle("branchId");
                }}
                style={{ width: 180 }}
              >
                <Option value={-1}>全部</Option>
                {props.departmentData.map(item => {
                  return (
                    <Option key={item.branchId} value={item.branchId}>
                      {item.branchName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item name="type" label="分类" style={{ width: 250 }}>
              {selectDom("type")}
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item name="bankAccount" label="账户名">
              {selectDom("bankAccount")}
              {/* <Input placeholder="输入账户名查询" /> */}
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item name="summary" label="摘要">
              <Input placeholder="输入摘要查询" />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item name="memo" label="备注">
              <Input placeholder="输入备注查询" />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item name="importUser" label="导入人">
              <Select
                allowClear
                listHeight={180}
                showSearch
                onChange={handleChange}
                placeholder="请选择导入人"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                style={{ width: 180 }}
              >
                {selectUser.map(item => {
                  return (
                    <Option key={item.userId} value={item.userId}>
                      {item.userName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col offset={1}>
            <Form.Item>
              <Space size="middle">
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
                <Button htmlType="button" onClick={onReset}>
                  {" "}
                  重置
                </Button>
                {buttonDom()}
                <Popconfirm
                  title={`请确认是否删除 ${
                    currentAccountId
                      ? `[ ${accountName} ]`
                      : currentBranchId.branchName
                      ? "[ " + currentBranchId.branchName + " ]"
                      : ""
                  } ${startTime} 日之后的所有数据？`}
                  onConfirm={delBillHandle}
                  onCancel={() => setVisible(false)}
                  visible={visible}
                  onVisibleChange={handleVisibleChange}
                  okText="确认"
                  cancelText="取消"
                  style={{ width: "400px !important" }}
                >
                  <Button>删除</Button>
                </Popconfirm>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default connect(state => ({
  departmentData: state.userInfo.userInfo.chooseRespList,
  userInfo: state.userInfo.userInfo
}))(SearchTemplate);
