import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef
} from "react";
import { Modal, Form, Input, Button, Space, message, /* Select */ } from "antd";
import { editBillApi } from "../../../../api/bill";
// const { Option } = Select;
const EditUserModal = (props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({});
  const formRef = useRef();
  const modalRef = useRef();
  // 响应父组件调用方法
  useImperativeHandle(ref, () => {
    return {
      initFn: data => {
        setIsModalVisible(true);
        setFormData(data);
        let timer = setTimeout(() => {
          clearTimeout(timer);
          if (data) {
            formRef.current && formRef.current.setFieldsValue(data);
          }
        });
      }
    };
  });
  const onFinish = async val => {
    let res = await editBillApi({ ...val, billId: formData.billId });
    if (res.code === 200) {
      message.success("操作成功");
      props.getData();
    }
    setIsModalVisible(false);
  };
  // 选择部门
  // function handleChange(val) {
  //   console.log(val);
  // }
  // function selectDom(type) {
  //   return (
  //     <Select
  //       listHeight={180}
  //       showSearch
  //       onChange={handleChange}
  //       optionFilterProp="children"
  //       filterOption={(input, option) =>
  //         option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  //       }
  //       filterSort={(optionA, optionB) =>
  //         optionA.children
  //           .toLowerCase()
  //           .localeCompare(optionB.children.toLowerCase())
  //       }
  //     >
  //       {props.selectData.map(item => {
  //         return (
  //           <Option key={item} value={item}>
  //             {item}
  //           </Option>
  //         );
  //       })}
  //     </Select>
  //   );
  // }
  return (
    <div ref={modalRef}>
      <Modal
        title={formData ? "修改" : "添加"}
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          name="basic"
          initialValues={{ remember: true, status: true }}
          onFinish={onFinish}
          ref={formRef}
        >
          <Form.Item
            label="摘要"
            name="summary"
            getValueFromEvent={event => event.target.value.replace(/\s+/g, "")}
          >
            <Input placeholder="请输入摘要" />
          </Form.Item>
          <Form.Item
            label="备注"
            name="memo"
            getValueFromEvent={event => event.target.value.replace(/\s+/g, "")}
          >
            <Input placeholder="请输入备注" />
          </Form.Item>
          <Form.Item
            label="分类"
            name="type"
            rules={[
              {
                required: true,
                message: "选择分类"
              }
            ]}
            getValueFromEvent={event => event.target.value.replace(/\s+/g, "")}
          >
            {/* {selectDom()} */}
            <Input placeholder="请输入分类" />
          </Form.Item>
          <Form.Item
            wrapperCol={{ span: 24 }}
            style={{ textAlign: "right", marginTop: 30 }}
          >
            <Space size="middle">
              <Button
                htmlType="button"
                onClick={() => setIsModalVisible(false)}
              >
                取消
              </Button>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default forwardRef(EditUserModal);
