import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { Modal, Form, Input, Button, Space, Radio, Switch, message } from 'antd'
import { getAllRoleApi, addAdminUserApi, getUserInfoByIdApi, updateAdminUserApi } from '../../../../../api/admin-users';

const EditUserModal = (props, ref) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [roleData, setRoleData] = useState([])
    const [isSwitch, setIsSwitch] = useState(true);
    const [formData, setFormData] = useState({})
    const [radioValue, setradioValue] = useState()
    const formRef = useRef()
    const modalRef = useRef()
    // 响应父组件调用方法
    useImperativeHandle(ref, () => {
        return {
            initFn: (data) => {
                setIsModalVisible(true)
                setFormData(data)
                if (data) {
                    setIsSwitch(data.status)
                    getUserInfoByIdApi({ userId: data.userId }).then(res => {
                        if (res.code === 200) {
                            let result = { ...res.data, roleId: res.data.adminRoleRespDtoList.map(item => item.roleId)[0], }
                            setradioValue(res.data.adminRoleRespDtoList.map(item => item.roleId)[0])
                            formRef.current.setFieldsValue(result)
                        }
                    })
                } else {
                    setIsSwitch(true)
                    let timer = setTimeout(() => {
                        clearTimeout(timer)
                        formRef.current.resetFields()
                    })
                }
            }
        }
    })
    useEffect(() => {
        // 获取全部角色
        getAllRoleApi().then(res => {
            if (res.code === 200) {
                const result = res.data.map(item => ({ label: item.roleName, value: item.roleId }))
                setRoleData(result)
            }
        })
    }, [])
    const onFinish = async (val) => {
        let res
        if (formData) {
            res = await updateAdminUserApi({ ...val, userId: formData.userId, roleId: [val.roleId] })
        } else {
            res = await addAdminUserApi({...val, roleId: [val.roleId]})
        }
        if (res.code === 200) {
            message.success('操作成功')
            props.getUserInfo()
            props.getData()
        } 
        setIsModalVisible(false)
    }
    const onChange = (e) => {
        setradioValue(e.target.value)
    }
    const onSwitchChange = (val) => {
        setIsSwitch(val)
    }
    return (
        <div ref={modalRef}>
            <Modal title={formData ? '修改' : '添加'} visible={isModalVisible} footer={null} onCancel={() => setIsModalVisible(false)}>
                <Form
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 16 }}
                    name="basic"
                    initialValues={{ remember: true, status: true, roleId: radioValue }}
                    onFinish={onFinish}
                    ref={formRef}
                >
                    <Form.Item
                        label="用户工号"
                        name="jobNum"
                        rules={[{ required: true, message: '请输入用户工号' }]}
                    >
                        <Input placeholder="请输入用户工号" disabled={formData}/>
                    </Form.Item>

                    {/* <Form.Item
                        label="密码"
                        name="password"
                    >
                        <Input.Password placeholder="请输入密码" />
                    </Form.Item> */}
                    <Form.Item
                        label="角色"
                        name="roleId"
                    >
                        <Radio.Group options={roleData} onChange={onChange} value={radioValue} />
                    </Form.Item>
                    <Form.Item
                        label="状态"
                        name="status"
                    >
                        <Switch checked={isSwitch} onChange={onSwitchChange} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right', marginTop: 30 }}>
                        <Space size="middle">
                            <Button htmlType="button" onClick={() => setIsModalVisible(false)}>
                                取消
                            </Button>
                            <Button type="primary" htmlType="submit">
                                确定
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default forwardRef(EditUserModal);
