import React, { useEffect, useState, useRef } from 'react';
import { Card, Space, Button, Popconfirm, message, Modal, Form, Input } from 'antd';
import { connect } from 'react-redux';
import { findBranchApi, delBranchApi, addBranchApi, editBranchApi } from '../../../../api/admin-users';
import { getUserInfo } from '../../../../store/actions/user.action';
import TableWrap from '../../../../components/common/TableWrap/index'

const Department = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [menuData, setMenuData] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [formData, setformData] = useState({})
    const [pageSize, setpageSize] = useState(10)
    const [currentPage, setcurrentPage] = useState(1)
    const [total, settotal] = useState(0)
    const formRef = useRef()
    useEffect(() => {
        getBranchList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const columns = [{
        title: '部门ID',
        dataIndex: 'branchId',
        width: 100
    },
    {
        title: '部门名称',
        dataIndex: 'branchName',
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
    },
    {
        title: '操作',
        dataIndex: 'action',
        width: 220,
        render: (text, record) => {
            return (
                <Space>
                    <Button size="small" type="primary" onClick={() => handleClickChange(1, record)}>修改</Button>
                    <Popconfirm
                        title="是否要删除该菜单?"
                        onConfirm={() => deleteConfirm(record)}
                        onCancel={() => { }}
                        okText="确认"
                        cancelText="取消"
                    >
                        <Button size="small" danger type="primary">删除</Button>
                    </Popconfirm>
                </Space>
            )
        }
    }]
    const getBranchList = () => {
        findBranchApi({
            data: {},
            pageNo: currentPage,
            pageSize: pageSize
        }).then(res => {
            if (res.code === 200) {
                setMenuData(res.data.list)
                settotal(res.data.total)
            } 
            setIsLoading(false)
        })
    }
    const deleteConfirm = (record) => {
        delBranchApi({ branchId: record.branchId }).then(res => {
            if (res.code === 200) {
                message.success('删除成功')
                setMenuData(menuData.filter(item => item.branchId !== record.branchId))
            } 
        })
    }
    const onFinish = async (val) => {
        let res
        if (formData) {
            res = await editBranchApi({ ...val, branchId: formData.branchId })
        } else {
            res = await addBranchApi(val)
        }
        if (res.code === 200) {
            getBranchList()
            message.success(formData ? '编辑成功' : '添加成功')
            setIsModalVisible(false)
        }
    }
    const handleClickChange = (type, data) => {
        setIsModalVisible(true)
        if (formRef.current) {
            formRef.current.resetFields()
        }
        setformData(data)
        if (type !== 0) {
            let timer = setTimeout(() => {
                clearTimeout(timer)
                formRef.current.setFieldsValue({ ...data })
            })
        }
    }
    function onPageChange (current, size) {
        setpageSize(size)
        setcurrentPage(current)
    }
    return (
        <Card style={{ height: '100%' }}>
            <Button type="primary" onClick={() => handleClickChange(0)}>新增</Button>
            <TableWrap
                rowKey={'branchId'}
                columns={columns}
                pageList={menuData}
                onPageChange={onPageChange}
                currentPage={currentPage}
                total={total}
                isLoading={isLoading}
                bordered
            />
            <Modal title={formData ? '编辑' : '添加'} visible={isModalVisible} footer={null} onCancel={() => setIsModalVisible(false)}>
                <Form
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{}}
                    onFinish={onFinish}
                    ref={formRef}
                >
                    <Form.Item
                        label="部门名称"
                        name="branchName"
                        rules={[{ required: true, message: '请输入部门名称' }]}
                    >
                        <Input placeholder="请输入部门名称"/>
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right', marginTop: 35 }}>
                        <Space>
                            <Button htmlType="button" onClick={() => setIsModalVisible(false)}>
                                取消
                            </Button>
                            <Button type="primary" htmlType="submit">
                                提交
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </Card>
    );
}

export default connect(
    state => ({}),
    {
        getUserInfo
    }
)(Department);
