import { handleActions as createReducer } from "redux-actions";
import { saveUserInfo, clearUserInfo, saveUserToken } from '../actions/user.action'

const initialState = {
    token: localStorage.getItem('Authorization'),
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
}
// 保存用户token
const saveUserTokenFn = (state, action) => {
    return { ...state, token: action.payload }
}
// 保存用户数据
const saveUserInfoFn = (state, action) => {
    return { ...state, userInfo: action.payload }
}
// 清空用户数据
const clearUserInfoFn = (state, action) => {
    return state
}

export default createReducer({
    [saveUserInfo]: saveUserInfoFn,
    [clearUserInfo]: clearUserInfoFn,
    [saveUserToken]: saveUserTokenFn
}, initialState)