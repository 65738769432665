import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import {
    Modal,
    Input,
    Switch,
    Radio,
    Form,
    Space,
    Button,
    message
} from 'antd'
import { addMenuApi, updateMenuApi } from '../../../../../api/admin-users'

import CascaderWrap from './cascaderWrap'


const EditModal = (props, ref) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [radioValue, setradioValue] = useState(1);
    const [switchValue, setswitchValue] = useState(true);
    const [parentMenuId, setparentMenuId] = useState(0);
    const [formData, setformData] = useState({})
    const layout = {
        labelCol: { span: 7 },
        wrapperCol: { span: 13 }
    }
    const formRef = useRef()
    // 子组件向父组件传递信息
    useImperativeHandle(ref, () => {
        return {
            initFn: (data) => {
                setIsModalVisible(true)
                setformData(data)
                if (data) {
                    let timer = setTimeout(() => {
                        clearTimeout(timer)
                        setradioValue(data.isPage ? 2 : 1)
                        setswitchValue(data.status)
                        formRef.current.setFieldsValue({ ...data, isPage: data.isPage ? 2 : 1 })
                    })
                } else {
                    setradioValue(1)
                    setswitchValue(true)
                    let timer = setTimeout(() => {
                        clearTimeout(timer)
                        formRef.current.resetFields()
                    });
                }
            }
        }
    })
    // 是否展示选择父级id
    const isShowFormItem = radioValue => {
        if (radioValue === 2) {
            return (
                <Form.Item
                    label="父级菜单"
                    name="parentMenuId"
                >
                    <CascaderWrap menuData={props.menuData} getChildData={getChildData} />
                </Form.Item>
            )
        }
    }
    // 获取父级菜单id
    const getChildData = (data) => {
        setparentMenuId(data.length > 0 ? data[0] : 0)
    }
    // 切换switch状态
    const onSwitchChange = (val) => {
        setswitchValue(val)
    }
    // 单选切换
    const onRadioChange = (val) => {
        setradioValue(val.target.value)
    }
    // 表单提交
    const onFinish = async (value) => {
        const params = {
            ...value,
            isPage: value.isPage === 2,
            parentMenuId: value.isPage === 1 ? 0 : parentMenuId,
            status: switchValue
        }
        let res
        if (formData) {
            res = await updateMenuApi({ ...params, menuId: formData.menuId })
        } else {
            res = await addMenuApi(params)
        }
        if (res.code === 200) {
            message.success('操作成功')
            props.getUserInfo()
            props.getData()
        }
        setIsModalVisible(false)
    }
    return (
        <Modal title={formData ? '修改' : '添加'} visible={isModalVisible} footer={null} onCancel={() => setIsModalVisible(false)}>
            <Form
                {...layout}
                initialValues={{ isPage: 1 }}
                name="basic"
                onFinish={onFinish}
                ref={formRef}
            >
                <Form.Item
                    label="类型"
                    name="isPage"
                >
                    <Radio.Group value={radioValue} onChange={onRadioChange} >
                        <Radio value={1}>目录</Radio>
                        <Radio value={2}>菜单</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="菜单名称"
                    name="menuName"
                    rules={[{ required: true, message: '请输入菜单名称' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="菜单路径"
                    name="menuPath"
                    rules={[{ required: true, message: '请输入菜单路径' }]}
                >
                    <Input />
                </Form.Item>
                {isShowFormItem(radioValue)}
                <Form.Item
                    label="菜单排序"
                    name="sort"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="菜单状态"
                    name="status"
                >
                    <Switch checked={switchValue} onChange={onSwitchChange} />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right' }}>
                    <Space size="middle">
                        <Button htmlType="button" onClick={() => setIsModalVisible(false)}>取消</Button>
                        <Button htmlType="submit" type="primary" >确认</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal >
    );
}

export default forwardRef(EditModal);
