import { takeEvery, put } from "redux-saga/effects";
import { message } from "antd";
import { loginApi, logoutApi, getUserInfoApi, ddLoginApi } from "../../api/login";
import {
  login,
  logout,
  saveUserInfo,
  clearUserInfo,
  saveUserToken,
  getUserInfo,
} from "../actions/user.action";
import { getFootTitle } from "../actions/admin.action";
import store from "../index";
import { useHistory } from "react-router-dom";

function* saveUserTokenFn(action) {
  let userToken = "";
  yield ddLoginApi(action.payload).then((res) => {
    if (res.code === 200) {
      localStorage.setItem(
        "Authorization",
        `${res.data.tokenHead}${res.data.token}`
      );
      let timer = setTimeout(() => {
        clearTimeout(timer);
        message.success("登录成功");
      }, 600);
      userToken = res.data.tokenHead + res.data.token;
      store.dispatch(getFootTitle());
    } else {
      message.error({
        content: res.msg,
        key: res.code,
      });
    }
  });
  yield put(saveUserToken(userToken));
}
function* getUserInfoFn(action) {
  let userInfo = {};
  yield getUserInfoApi().then((res) => {
    if (res.code === 200) {
      localStorage.setItem("userInfo", JSON.stringify(res.data));
      userInfo = res.data;
    }
  });
  yield put(saveUserInfo(userInfo));
}
function* clearUserInfoFn(action) {
  yield logoutApi().then((res) => {
    if (res.code === 200) {
      let history = useHistory();
      try {
        localStorage.clear();
        history.push("/login");
      } catch (error) {
        history.push("/login");
      }
      // window.location.href = window.location.origin + '/#/login'
    }
  });
  yield put(clearUserInfo());
}

export function* saveTokenSaga() {
  yield takeEvery(login, saveUserTokenFn);
}
export function* saveUserinfoSaga() {
  yield takeEvery(getUserInfo, getUserInfoFn);
}
export function* clearSaga() {
  yield takeEvery(logout, clearUserInfoFn);
}
