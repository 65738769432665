import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Space,
  Tag,
  Button,
  Card,
  Row,
  Col,
  Input,
  Select,
  Popconfirm,
  message,
  Pagination
} from "antd";
import {
  getRoleListApi,
  getTreeListApi,
  delRoleApi,
  getResourceTreeListApi,
  findBranchChooseApi
} from "../../../../api/admin-users";
import AddResources from "./modules/addResources";
import EditRoleModal from "./modules/editModal";
import { connect } from "react-redux";
import { getUserInfo } from "../../../../store/actions/user.action";

const { Option } = Select;

function User(props) {
  const [userList, setUserList] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({
    status: "",
    roleName: ""
  });
  const [pageParams, setPageParams] = useState({
    pageNo: 1,
    pageSize: 10
  });
  const [isShowResources, setIsShowResources] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [roleId, setRoleId] = useState(0);
  const editModal = useRef();
  const [branchList, setBranchList] = useState([]);
  const [total, setTotal] = useState(0);

  // 获取用户数据
  useEffect(() => {
    getRoleList();
    getTreeDataAll();
    getBranchList();
  }, [pageParams.pageSize, pageParams.pageNo]);
  // 表格数据结构
  const columns = [
    {
      title: "ID",
      dataIndex: "roleId"
    },
    {
      title: "用户名称",
      dataIndex: "roleName"
    },
    {
      title: "描述",
      dataIndex: "roleDesc"
    },
    {
      title: "查询权限",
      dataIndex: "type",
      render: status => (status ? "全部" : "个人")
    },
    {
      title: "状态",
      dataIndex: "status",
      render: status => (
        <Tag color={status ? "green" : "red"}>{status ? "正常" : "禁用"}</Tag>
      )
    },
    {
      title: "操作",
      key: "action",
      width: 100,
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              size="small"
              type="primary"
              onClick={() => editModal.current.initFn(record)}
            >
              修改
            </Button>
            <Popconfirm
              title="是否要删除该角色?"
              onConfirm={() => deleteUserById(record)}
              onCancel={() => {}}
              okText="确认"
              cancelText="取消"
            >
              <Button size="small" type="primary" danger>
                删除
              </Button>
            </Popconfirm>
            <Button
              size="small"
              type="primary"
              style={{ background: "#f6a000f5", borderColor: "#f6a000f5" }}
              onClick={openResources(record)}
            >
              分配资源
            </Button>
          </Space>
        );
      }
    }
  ];

  const getTreeDataAll = async () => {
    Promise.all([
      await getTreeListApi(),
      await getResourceTreeListApi({ status: 1 })
    ]).then(res => {
      setTreeData(res.map(item => item.data));
    });
  };
  const getRoleList = (roleName, status) => {
    const params = {
      data: {
        roleName,
        status
      },
      pageNo: pageParams.pageNo,
      pageSize: pageParams.pageSize
    };
    setisLoading(true);
    getRoleListApi(params).then(res => {
      if (res.code === 200) {
        setUserList(res.data.list);
        setTotal(res.data.total);
      }
      setisLoading(false);
    });
  };

  // 获取部门列表
  const getBranchList = () => {
    findBranchChooseApi().then(res => {
      if (res.code === 200) {
        let result = res.data.map(item => {
          return {
            label: item.branchName,
            value: item.branchId
          };
        });
        setBranchList(result);
      }
    });
  };
  // 用户名查询
  const handleInputChange = e => {
    if (e.type === "keyup" && e.keyCode === 13) {
      getRoleList(e.target.value, searchParams.status);
    }
    setSearchParams({ ...searchParams, roleName: e.target.value });
  };
  // 状态筛选
  const handleChange = value => {
    let type = "";
    if (value === 1) {
      type = true;
    } else if (value === 0) {
      type = false;
    }
    getRoleList(searchParams.roleName, type);
    setSearchParams({ ...searchParams, status: type });
  };
  // 搜索
  const handleSearch = e => {
    getRoleList(searchParams.roleName, searchParams.status);
  };
  // 删除角色
  const deleteUserById = record => {
    delRoleApi({ roleId: record.roleId }).then(res => {
      if (res.code === 200) {
        message.success("删除成功");
        getRoleList();
      }
    });
  };
  const openResources = record => {
    return () => {
      setIsShowResources(true);
      setRoleId(record.roleId);
    };
  };
  const getResourcesData = data => {
    setIsShowResources(data);
  };
  //   分页设置
  const onShowSizeChange = (pageNo, pageSize) => {
    setPageParams({
      pageNo,
      pageSize
    });
  };
  //   翻页
  const onChangePageNo = (pageNo, pageSize) => {
    setPageParams({
      pageNo,
      pageSize
    });
  };

  return (
    <Card style={{ height: "100%" }}>
      <Row>
        <Col>
          <Button type="primary" onClick={() => editModal.current.initFn()}>
            新增
          </Button>
        </Col>
        <Col offset={1}>
          <Input
            placeholder="输入用户名称查询"
            onChange={handleInputChange}
            onKeyUp={handleInputChange}
            allowClear
          />
        </Col>
        <Col offset={1}>
          <Space size="middle">
            <Select
              defaultValue={-1}
              style={{ width: 120 }}
              onChange={handleChange}
            >
              <Option value={-1}>全部</Option>
              <Option value={1}>正常</Option>
              <Option value={0}>禁用</Option>
            </Select>
            <Button type="primary" onClick={handleSearch}>
              搜索
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        style={{ marginTop: 20 }}
        columns={columns}
        bordered
        dataSource={userList}
        loading={isLoading}
        rowKey={record => (record.sourceId ? record.sourceId : record.roleId)}
        pagination={{ position: ["none"] }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "20px"
        }}
      >
        <Pagination
          showSizeChanger
          onChange={onChangePageNo}
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={setPageParams.pageNo}
          total={total}
        />
      </div>
      {isShowResources ? (
        <AddResources
          treeData={treeData}
          getData={getResourcesData}
          getUserInfo={props.getUserInfo}
          roleId={roleId}
        />
      ) : (
        ""
      )}
      <EditRoleModal
        ref={editModal}
        getData={getRoleList}
        branchList={branchList}
      />
    </Card>
  );
}

export default connect(state => ({}), {
  getUserInfo
})(User);
