import { handleActions as createReducer } from 'redux-actions'
import { refreshHomeData } from '../actions/common.action'
const initState = {
    isRefreshHomeData: 0
}
const refreshHomeDataFn = (state, action) => {
    return { isRefreshHomeData: state.isRefreshHomeData + 1 }
}

export default createReducer({
    [refreshHomeData]: refreshHomeDataFn
}, initState)