import { formatMoney } from './index'
export default function chartFormat(chartData = [], type, showType) {
    let result = {
        timerData: [],
        rel1: [],
        rel2: [],
        rel3: [],
        rel4: []
    }
    let keyOption
    let count = 0
    let balance = 0
    let availableBalance = 0
    for (let key in chartData) {
        if (type === 1) {
            keyOption = key
        } else if (type === 2) {
            count++
            keyOption = `${count === 1 || (key + '').slice(4) === '1' ? (key + '').slice(0, 4) + '/' : ''
                } Q${(key + '').slice(4)} `
        } else if (type === 3) {
            keyOption = `${key} 月`
        } else if (type === 4) {
            keyOption = `${key} 周`
        } else {
            keyOption = `${key.slice(5, 7)} /${key.slice(8)}`
        }
        result.timerData.push(keyOption)
        if (chartData[key]) {
            if (chartData[key].balance) {
                balance = chartData[key].balance
            }
            if (chartData[key].availableBalance) {
                availableBalance = chartData[key].availableBalance
            }
        }
        result.rel1.push(chartData[key] ? chartData[key].income : 0)
        result.rel2.push(chartData[key] ? chartData[key].expenditure : 0)
        result.rel3.push(balance)
        result.rel4.push(availableBalance)
    }
    let option = {
        color: ['#00a4ee', '#ff6c73', '#76df99', '#ffd75a'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: '#999'
                }
            },
            backgroundColor: 'rgba(0,0,0,.5)',
            borderWidth: 0,
            formatter: function (params) {
                let res = `
                    <div style="color: #fff; width: 180px">
                        <div>${params[0].name}</div>
                        <div style="display: flex;justify-content: space-between;"><span>${params[0] ? params[0].marker + params[0].seriesName : ''}</span>${params[0] ? formatMoney((params[0].data), 0) : ''}</div>
                        <div style="display: flex;justify-content: space-between;"><span>${params[1] ? params[1].marker + params[1].seriesName : ''}</span>${params[1] ? formatMoney((params[1].data), 0) : ''}</div>
                        <div style="display: flex;justify-content: space-between;"><span>${params[2] ? params[2].marker + params[2].seriesName : ''}</span>${params[2] ? formatMoney((params[2].data), 0) : ''}</div>
                        <div style="display: flex;justify-content: space-between;"><span>${params[3] ? params[3].marker + params[3].seriesName : ''}</span>${params[3] ? formatMoney((params[3].data), 0) : ''}</div>
                    </div>
                `
                return res
            }
        },
        toolbox: {
            feature: {
                // dataView: { show: true, readOnly: false },
                // magicType: { show: true, type: ['line', 'bar'] },
                // 刷新
                // restore: { show: true },
                // 下载图片
                // saveAsImage: { show: true }
            }
        },
        legend: {
            data: showType === 'payment' ? ['收入'] : ['收入', '支出', '可用余额', '总余额'],
            x: 'center',
            y: 'bottom'
        },
        xAxis: [
            {
                type: 'category',
                data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                axisPointer: {
                    type: 'shadow'
                }
            }
        ],
        yAxis: [],
        series: []
    };

    option.xAxis[0].data = result.timerData
    let series = []
    let yAxis = []
    if (showType === 'payment') {
        yAxis = [
            {
                type: 'value',
                name: 'CNY 1000',
                data: Array.from(new Set(result.rel2.concat(result.rel1))),
                axisLabel: {
                    formatter: '{value}'
                },
                axisTick: { //y轴刻度线
                    show: true
                },
                axisLine: { //y轴
                    show: true
                }
            }
        ]
        series = [
            {
                name: '收入',
                type: 'bar',
                data: result.rel1
            }
        ]
    } else {
        yAxis = [
            {
                type: 'value',
                name: 'CNY 1000',
                data: Array.from(new Set(result.rel2.concat(result.rel1))),
                axisLabel: {
                    formatter: '{value}'
                },
                axisTick: { //y轴刻度线
                    show: true
                },
                axisLine: { //y轴
                    show: true
                }
            },
            {
                type: 'value',
                name: '余额',
                data: Array.from(new Set(result.rel3)),
                axisLabel: {
                    formatter: '{value}'
                },
                axisTick: { //y轴刻度线
                    show: true
                },
                axisLine: { //y轴
                    show: true
                },
                splitLine: { show: false },   //去除网格线
            }
        ]
        series = [
            {
                name: '收入',
                type: 'bar',
                data: result.rel1
            },
            {
                name: '支出',
                type: 'bar',
                data: result.rel2
            },
            {
                name: '可用余额',
                type: 'line',
                yAxisIndex: 1,
                data: result.rel4
            },
            {
                name: '总余额',
                type: 'line',
                yAxisIndex: 1,
                data: result.rel3
            }
        ]
    }
    return {
        ...option,
        yAxis,
        series
    }
}


