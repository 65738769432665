import { all } from 'redux-saga/effects'
import { saveTokenSaga, clearSaga, saveUserinfoSaga } from './user.saga'
import { saveFootTitleSaga } from './admin-saga'

export default function* rootSaga () {
    yield all([
        saveTokenSaga(),
        clearSaga(),
        saveUserinfoSaga(),
        saveFootTitleSaga()
    ])
}