import React from "react";
import { Table, Pagination } from "antd";
import "./index.less";

const TableWrap = props => {
  const {
    rowKey /* key */,
    columns /* 配置项 */,
    pageList /* 数据 */,
    onPageChange /* 切换页 */,
    currentPage /* 当前页 */,
    total /* 总页数 */,
    isLoading /* 是否加载中 */,
    bordered /* 是否有边框 */,
    sticky /* 固定表头 */,
    isSummary /* 展示汇总数据 */,
    isShowPagination /* 是否展示分页 */,
    scroll /* 固定头 */,
    showBalance /* 是否展示银行卡统计 */,
    balanceCount /* 银行卡统计 */
  } = props;
  function tableFooter() {
    if (isSummary && pageList[0] && pageList[0].billRespDto) {
      return (
        <div className="sum">
          <span>
            支出：
            <span style={{ color: "red" }}>
              {`￥${pageList[0].billRespDto.expenditure}`}
            </span>
          </span>
          <span>
            收入：
            <span style={{ color: "green" }}>
              {`￥${pageList[0].billRespDto.income}`}
            </span>
          </span>
          {/* <span>余额：<span style={{ color: pageList[0].billRespDto.money >= 0 ? 'green' : 'red' }}>{pageList[0] ? `${pageList[0].billRespDto.money !== 0 ? '￥' : ''}${pageList[0].billRespDto.money}` : 0}</span></span> */}
        </div>
      );
    } else if (showBalance) {
      return (
        <div>
          总余额：
          <span style={{ color: "green" }}>
            ￥{balanceCount ? balanceCount : 0}
          </span>
        </div>
      );
    } else {
      return <div className="sum"></div>;
    }
  }
  function isShowPaginationFn() {
    if (isShowPagination !== "null") {
      return (
        <Pagination
          showSizeChanger
          onChange={onPageChange}
          current={currentPage}
          total={total}
          style={{ margin: "20px 0", textAlign: "right" }}
        />
      );
    }
  }
  return (
    <div>
      <Table
        style={{ marginTop: 20 }}
        columns={columns}
        dataSource={pageList}
        rowKey={record => record[rowKey]}
        pagination={false}
        loading={isLoading}
        bordered={bordered}
        sticky={sticky}
        scroll={scroll}
        size="small"
      />
      <div className="table-footer">
        {tableFooter()}
        {isShowPaginationFn()}
      </div>
    </div>
  );
};

export default TableWrap;
