import axios from 'axios'
import { message } from 'antd'
import { BSAE_URL } from './baseUrl'
import { useHistory } from 'react-router-dom'

/* 状态码 */
let httpCode = {
    400: '请求参数错误',
    401: '用户token失效，请重新登录',
    403: '服务器拒绝本次访问',
    404: '请求资源未找到',
    500: '内部服务器错误',
    501: '服务器不支持该请求中使用的方法',
    502: '网关错误',
    504: '网管超时'
}
const request = axios.create({
    timeout: 10000, // 设置超时时间
    baseURL: BSAE_URL, // 基础请求路径
});

// 请求数据类型格式
request.defaults.headers.post['Content-Type'] = 'application/json'
/* 请求拦截 */
request.interceptors.request.use(config => {
    const token = localStorage.getItem('Authorization')
    if (token) {
        config.headers['Authorization'] = token
    }
    return config
})

/* 响应拦截 */
request.interceptors.response.use(response => {
    const token = localStorage.getItem('Authorization')
    let { code, msg } = response.data
    switch (code) {
        case 400:
            return message.error(httpCode[code])
        case 401:
            if (token) {
                localStorage.clear()
                let timer = setTimeout(() => {
                    clearTimeout(timer)
                    useHistory().push('/login')
                }, 1000)
                // 如果token存在需要清除本地存储
            }
            return message.error({
                content: msg || httpCode[code],
                key: code
            })
        case 403:
            return message.error(msg || httpCode[code])
        case 404:
            return message.error(msg || httpCode[code])
        case 500:
            return message.error({
                content: msg || httpCode[code],
                key: code
            })
        case 501:
            return message.error({
                content: msg || httpCode[code],
                key: code
            })
        case 502:
            return message.error({
                content: msg || httpCode[code],
                key: code
            })
        case 504:
            return message.error({
                content: msg || httpCode[code],
                key: code
            })
        default:
            break;
    }
    return response.data
})


/* GET请求 */
export const GET = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        request({
            method: 'get',
            url,
            params
        }).then(res => resolve(res))
            .catch(err => reject(err))
    })
}

/* POST请求 */
export const POST = (url, params = {}, header = {}) => {
    return new Promise((resolve, reject) => {
        request({
            method: 'post',
            url,
            data: params,
            responseType: header.responseType ? header.responseType : ''
        }).then(res => resolve(res))
            .catch(err => reject(err))
    })
}