import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { Modal, Form, Space, Button, Input, /* Switch, */ message, Select } from 'antd'
import { addResourceApi, updateResourceApi } from '../../../../../api/admin-users';

const { Option } = Select

const EditModal = (props, ref) => {
    const [isModalVisible, setisModalVisible] = useState(false)
    const [formData, setformData] = useState({})
    // const [isSwitch, setisSwitch] = useState(true)
    const formRef = useRef()
    useImperativeHandle(ref, () => {
        return {
            initFn: (data) => {
                setisModalVisible(true)
                setformData(data)
                if (data) {
                    let timer = setTimeout(() => {
                        clearTimeout(timer)
                        formRef.current.setFieldsValue({ ...data})
                    })
                } else {
                    let timer = setTimeout(() => {
                        clearTimeout(timer)
                        formRef.current.resetFields()
                    })
                }
            }
        }
    })
    const onFinish = async (val) => {
        let res
        if (formData) {
            res = await updateResourceApi({ ...val, sourceId: formData.sourceId })
        } else {
            res = await addResourceApi(val)
        }
        if (res.code === 200) {
            props.getData()
            message.success(formData ? '编辑成功' : '添加成功')
        }
        setisModalVisible(false)
    }
    // function onSwitchChange (val) {
    //     setisSwitch(val)
    // }
    const handleChange = (val) => {
    }
    return (
        <Modal title={formData ? '修改' : '添加'} visible={isModalVisible} footer={null} onCancel={() => setisModalVisible(false)}>
            <Form labelCol={{ span: 5 }}
                wrapperCol={{ span: 16 }} ref={formRef} onFinish={onFinish}>
                <Form.Item label="所属分类" name="cateId">
                    <Select onChange={handleChange}>
                        {
                            props.resourceTreeList.map(item => {
                                return <Option key={item.cateId} value={item.cateId}>{item.cateName}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="资源名称" name="sourceName" rules={[{ required: true, message: '请输入资源名称' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="资源URL" name="sourceUrl">
                    <Input />
                </Form.Item>
                <Form.Item label="资源描述" name="sourceDesc">
                    <Input />
                </Form.Item>
                {/* <Form.Item label="资源状态" name="status">
                    <Switch checked={isSwitch} onChange={onSwitchChange} />
                </Form.Item> */}
                <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right', marginTop: 30 }}>
                    <Space size="middle">
                        <Button htmlType='button' onClick={() => setisModalVisible(false)}>取消</Button>
                        <Button type="primary" htmlType="submit">确定</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default forwardRef(EditModal);
