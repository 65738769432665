import React, { forwardRef, useState, useImperativeHandle } from "react";
import { Modal, message, Table } from "antd";
import {
  branchDayReportApi,
  findAccountDayReportApi,
  findBranchMoneyDetailsReportApi
} from "../../../../api/report";
import moment from "moment";
import { formatMoney, watermark } from "../../../../utils";
import "./index.less";

function ViewModal(props, ref) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [branchDayList, setBranchDayList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState({});
  const [columns, setColumns] = useState([]);
  const [result, setResult] = useState([]);
  const [recordParams, serRecordParams] = useState({});
  const initColumns = [
    {
      title: "收入",
      dataIndex: "income",
      width: 90,
      render: text => <span>{formatMoney(text, 2)}</span>
    },
    {
      title: "支出",
      width: 90,
      dataIndex: "expenditure",
      render: text => <span>{formatMoney(text, 2)}</span>
    },
    {
      title: "余额",
      width: 120,
      dataIndex: "balance",
      render: text => <span>{formatMoney(text, 2)}</span>
    }
  ];
  useImperativeHandle(ref, () => {
    return {
      // 初始化
      initFn: async data => {
        if (!data.branchId || data.branchId.length === 0)
          return message.warning({
            content: "请选择事业部",
            key: "branchId"
          });
        let params;
        if (data.type != "capital") {
          if (!data.date || data.date.length < 2) {
            return message.warning({ content: "请选择日期", key: "date" });
          }
          params = {
            ...data,
            beginTime: moment(data.date[0]).format("YYYY-MM-DD"),
            endTime: moment(data.date[1]).format("YYYY-MM-DD")
          };
        } else {
          if (!data.time) {
            return message.warning({ content: "请选择日期", key: "date" });
          }
          params = { ...data };
        }
        serRecordParams(data);
        setIsModalVisible(true);
        setIsLoading(true);
        let res;
        if (params.type === "capital") {
          setTitle(`账户日报表 ${moment(params.time).format("YYYY-MM-DD")}`);
          setColumns([
            // {
            //   title: "账单日期",
            //   dataIndex: "billTime",
            //   width: 130
            // },
            {
              title: "账户名",
              dataIndex: "bankAccount",
              width: 260
            },
            {
              title: "昨日余额",
              dataIndex: "lastBalance",
              width: 120,
              render: text => <span>{formatMoney(text, 2)}</span>
            },
            ...initColumns
          ]);
          // delete params.type;
          res = await findAccountDayReportApi(params);
        } else if (params.type === "income") {
          setTitle(
            `收入回款表 (${moment(params.date[0]).format(
              "YYYY-MM-DD"
            )} 至 ${moment(params.date[1]).format("YYYY-MM-DD")})`
          );
          setColumns([
            {
              title: "账单日期",
              dataIndex: "billTime",
              width: 130
            },
            {
              title: "部门名称",
              dataIndex: "branchName"
            },
            {
              ...initColumns[0],
              width: ""
            }
          ]);
          // delete params.type;
          res = await findBranchMoneyDetailsReportApi({
            ...params,
            beginTime: moment(params.date[0]).format("YYYY-MM-DD"),
            endTime: moment(params.date[1]).format("YYYY-MM-DD")
          });
        } else if (params.type === "branch") {
          setTitle(
            `部门日报表 (${moment(params.date[0]).format(
              "YYYY-MM-DD"
            )} 至 ${moment(params.date[1]).format("YYYY-MM-DD")})`
          );
          setColumns([
            // {
            //   title: "账单日期",
            //   dataIndex: "billTime",
            //   width: 130
            // },
            {
              title: "部门名称",
              dataIndex: "branchName",
              width: 140,
              render: text => <span style={{ fontWeight: 700 }}>{text}</span>
            },
            {
              title: "昨日余额",
              dataIndex: "lastBalance",
              render: text => (
                <span style={{ fontWeight: 700 }}>{formatMoney(text, 2)}</span>
              )
            },
            {
              title: "收入",
              dataIndex: "income",
              render: text => <span>{formatMoney(text, 2)}</span>
            },
            {
              title: "支出",
              dataIndex: "expenditure",
              render: text => <span>{formatMoney(text, 2)}</span>
            },
            {
              title: "余额",
              dataIndex: "balance",
              render: text => (
                <span style={{ fontWeight: 700 }}>{formatMoney(text, 2)}</span>
              )
            },

            {
              title: "可动用资金",
              children: [
                {
                  title: "公户",
                  dataIndex: "public",
                  render: text => <span>{formatMoney(text, 2)}</span>
                },
                {
                  title: "个人",
                  dataIndex: "personal",
                  render: text => <span>{formatMoney(text, 2)}</span>
                }
                // ,

                // {
                //   title: "证券",
                //   dataIndex: "bond",
                //   render: text => <span>{formatMoney(text, 2)}</span>
                // }
              ]
            },
            {
              title: "不可动用资金",
              children: [
                {
                  title: "店铺",
                  dataIndex: "shop",
                  render: text => <span>{formatMoney(text, 2)}</span>
                },
                {
                  title: "冻结",
                  dataIndex: "frozen",
                  render: text => <span>{formatMoney(text, 2)}</span>
                },
                {
                  title: "基金",
                  dataIndex: "fund",
                  render: text => <span>{formatMoney(text, 2)}</span>
                },
                // {
                //   title: "股票",
                //   dataIndex: "shares",
                //   render: text => <span>{formatMoney(text, 2)}</span>
                // },
                {
                  title: "法人账户",
                  dataIndex: "legal",
                  render: text => <span>{formatMoney(text, 2)}</span>
                }
                // ,
                // {
                //   title: "提现账户",
                //   dataIndex: "drawing",
                //   render: text => <span>{formatMoney(text, 2)}</span>
                // }
              ]
            }
          ]);
          // delete params.type;
          res = await branchDayReportApi(params);
        }
        setIsLoading(false);
        // TODO 水印
        // watermark({
        //   content: `${props.userInfo.userName} ${moment()
        //     .locale("zh-cn")
        //     .format("YYYY-MM-DD")}`,
        //   container: document.querySelector(".ant-table-wrapper")
        // });

        if (res.code === 200) {
          setBranchDayList([]);
          let data = res.data;
          if (data.length > 0) {
            data[res.data.length - 1].bankAccount = "合计";
            data[res.data.length - 1].showClass = true;
          }
          let resultArr = [[], []];
          let sum = data.length > 30 ?  data.length / 2 : data.length;
          data.forEach((it, i) => {
            it.rowId = i;
            if (params.type == "capital") {
              if (i < sum) {
                resultArr[0].push(it);
              } else {
                resultArr[1].push(it);
              }
            }
          });
          setResult(resultArr);
          setBranchDayList(data);
        }
      }
    };
  });

  function rowClassNameFn(record, index) {
    let styleClass;
    if (index % 2 === 1 && record.bankAccount != "合计") {
      styleClass = "table_record_style";
    } else if (record.bankAccount == "合计") {
      styleClass = "sum_style";
    } else {
      styleClass = "";
    }
    return styleClass;
  }
  // 查看数据列表
  function dayCapitalTable() {
    if (recordParams.type == "capital" && result.length > 0) {
      return result.map((item, index) => {
        if (item.length > 0) {
          return (
            <Table
              key={index}
              className="view_modal table_title_style"
              rowClassName={(record, index) => rowClassNameFn(record, index)}
              style={{ margin: "10px 0" }}
              columns={columns}
              dataSource={item}
              rowKey={record => record.rowId}
              pagination={false}
              loading={isLoading}
              bordered
            />
          );
        }
      });
    } else {
      return (
        <Table
          className="view_modal table_title_style"
          rowClassName={(record, index) => rowClassNameFn(record, index)}
          style={{ margin: "20px 0", flex: 1 }}
          columns={columns}
          dataSource={branchDayList}
          rowKey={record => record.rowId}
          pagination={false}
          loading={isLoading}
          bordered
          scroll={{ y: 600 }}
        />
      );
    }
  }
  function getWidthNum() {
    let num = "";
    if (recordParams.type == "capital") {
      if (branchDayList.length > 30) {
        num = "80%";
      } else {
        num = "50%";
      }
    } else if (recordParams.type == "branch") {
      num = "95%";
    } else {
      num = "50%";
    }
    return num;
  }
  return (
    <Modal
      title={title}
      width={getWidthNum()}
      bodyStyle={{ paddingTop: 0 }}
      visible={isModalVisible}
      footer={null}
      onCancel={() => setIsModalVisible(false)}
    >
      <div className="table_flex">
        {/* 表格数据渲染 */}
        {dayCapitalTable()}
        {/* <Table
          className="view_modal"
          style={{ margin: "20px 0" }}
          columns={columns}
          dataSource={branchDayList}
          rowKey={record => record.rowId}
          pagination={false}
          loading={isLoading}
          bordered
        /> */}
      </div>
    </Modal>
  );
}
export default forwardRef(ViewModal);
