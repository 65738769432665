import React from 'react';
// import TableWrap from '../../components/common/TableWrap/index'

const NotFound = () => {
    return (
        <div>
            {/* <TableWrap /> */}
            404
        </div>
    );
}

export default NotFound;
