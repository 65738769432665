import { takeEvery, put } from 'redux-saga/effects'
import { getCopyrightLogoApi } from '../../api/admin-users'
import { getFootTitle, saveFootTitle } from '../actions/admin.action'

function* getFootTitleFn () {
    let footTitleData = ''
    yield getCopyrightLogoApi().then(res => {
        if (res.code === 200 && res.data) {
            footTitleData = res.data.copyrightLogo
            localStorage.setItem('footTitle', JSON.stringify(res.data.copyrightLogo))
        }
    })

    yield put(saveFootTitle(footTitleData))
}

export function* saveFootTitleSaga () {
    yield takeEvery(getFootTitle, getFootTitleFn)
}