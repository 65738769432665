import { POST } from '../config/server'

/* 导出 部门日报表*/
export const dailyMoneyReportApi = (params, header) => POST('/report/dailyMoneyReport', params, header)

/* 导出 收支明细报表 */
export const moneyDetailsReportApi = (params, header) => POST('/report/branchMoneyDetailsReport', params, header)

/* 查看 部门日报表 */
export const branchDayReportApi = params => POST('/report/branchDayReport', params)

/* 查看 账户资金日报表 */
export const findAccountDayReportApi = params => POST('/report/findAccountDayReport', params)

/* 查看 部门收支明细报表 */
export const findBranchMoneyDetailsReportApi = params => POST('/report/findBranchMoneyDetailsReport', params)

/* 导出部门日报表 */
export const exportBranchDayReportApi = (params, header) => POST('/report/exportBranchDayReport', params, header)


