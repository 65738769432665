import React, { useEffect, useState } from 'react';
import { Cascader } from 'antd'
const CascaderWrap = (props) => {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        const menuList = props.menuData.map(item => setRouteTree(item))
        setOptions(menuList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setRouteTree = (org) => { // 遍历树  获取id数组
        const haveChildren = Array.isArray(org.children) && org.children.length > 0
        return {
            // 分别将我们查询出来的值做出改变他的key
            value: org.menuId,
            label: org.menuName,
            // 判断它是否存在子集，若果存在就进行再次进行遍历操作，知道不存在子集便对其他的元素进行操作
            children: haveChildren ? org.children.map(i => setRouteTree(i)) : []
        }
    }
    return (
        <div>
            <Cascader options={options} expandTrigger="hover" onChange={val => props.getChildData(val)} changeOnSelect placeholder="请选择父级菜单" />
        </div>
    );
}

export default CascaderWrap;
