import React, { useEffect, useState, useRef } from "react";
import { Card, message, Tabs, Popover, Button } from "antd";
import { findBillApi, findBillTypeApi } from "../../../api/bill";
import SearchTemplate from "./searchTemplate";
import { connect } from "react-redux";
import TableWrap from "../../../components/common/TableWrap/index";
import "./search.less";
import EditModal from "./modules/editModal";
const { TabPane } = Tabs;

function Bill(props) {
  const [billData, setBillData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState("all");
  const [total, settotal] = useState(0);
  const [currentPage, setcurrentPage] = useState(1);
  const [searchParams, setsearchParams] = useState({});
  const [selectData, setselectData] = useState([]);

  let modalRef = useRef();

  useEffect(() => {
    const params = { data: {}, pageNo: 1, pageSize: 10 };
    getBillData(params);
    findBillTypeApi().then(res => {
      setselectData(res.data);
    });
  }, [props.commonStore.isRefreshHomeData]);
  // 获取对账单信息
  const getBillData = params => {
    setIsLoading(true);
    // message.loading('加载中...')
    findBillApi({ ...params }).then(res => {
      message.destroy();
      setIsLoading(false);
      if (res.code === 200) {
        settotal(res.data.total);
        setBillData(res.data.list);
      }
    });
  };
  const columns = [
    {
      title: "账单日期",
      dataIndex: "billTime",
      width: 100
    },
    {
      title: "账户名",
      dataIndex: "bankAccount",
      width: 220,
    },
    {
      width: 280,
      title: "摘要",
      dataIndex: "summary"
      // render: text => columnData(text),
      // ellipsis: true
    },
    {
      title: "支出",
      dataIndex: "expenditure",
      render: text => (
        <span style={{ color: currentTab !== "income" && text ? "red" : "" }}>
          {currentTab !== "income" && text ? "-" + text : "--"}
        </span>
      )
    },
    {
      title: "收入",
      dataIndex: "income",
      render: text => (
        <span style={{ color: currentTab !== "expend" && text ? "green" : "" }}>
          {currentTab !== "expend" && text ? "+" + text : "--"}
        </span>
      )
    },
    {
      title: "余额",
      dataIndex: "balance",
      width: 80
    },
    {
      width: 100,
      title: "备注",
      dataIndex: "memo",
      render: text => columnData(text),
      ellipsis: true
    },
    {
      title: "分类",
      dataIndex: "type",
      width: 100
    },
    {
      title: "部门名称",
      dataIndex: "branchName",
      width: 120
    },
    {
      title: "导入人",
      dataIndex: "userName",
      width: 100
    },
    {
      title: "导入时间",
      dataIndex: "createTime"
    },
    {
      title: "操作",
      dataIndex: "address",
      align: "center",
      width: 70,
      render: (text, record) => {
        return (
          <Button type="link" onClick={() => modalRef.current.initFn(record)}>
            修改
          </Button>
        );
      }
    }
  ];
  function columnData(record) {
    if (record) {
      if (record.length >= 6) {
        return (
          <Popover placement="topLeft" content={record} trigger="hover">
            <span style={{ cursor: "pointer" }}>{record.substring(0, 7)}</span>
          </Popover>
        );
      } else {
        return <span>{record}</span>;
      }
    } else {
      return "--";
    }
  }
  const search = data => {
    let params =
      currentTab === "expend"
        ? { expenditure: 1 }
        : currentTab === "income"
        ? { income: 1 }
        : {};
    setsearchParams(data);
    getBillData({ data: { ...params, ...data }, pageNo: 1, pageSize: 10 });
  };
  // tab切换
  const onTabChange = val => {
    let params =
      val === "expend"
        ? { expenditure: 1 }
        : val === "income"
        ? { income: 1 }
        : {};
    getBillData({
      data: { ...searchParams, ...params },
      pageNo: 1,
      pageSize: 10
    });
    setCurrentTab(val);
    setcurrentPage(1);
  };
  function onPageChange(val, pageSize) {
    let params =
      currentTab === "expend"
        ? { expenditure: 1 }
        : currentTab === "income"
        ? { income: 1 }
        : {};
    getBillData({
      data: { ...searchParams, ...params },
      pageNo: val,
      pageSize: pageSize
    });
    setcurrentPage(val);
  }
  return (
    <Card
      className="card-container"
      style={{
        width: "100%"
      }} /* tabList={tabListNoTitle} onTabChange={onTabChange} */
    >
      <div className="search-params">
        <Tabs
          className="tabs-container"
          defaultActiveKey="all"
          onChange={onTabChange}
        >
          <TabPane tab="全部" key="all"></TabPane>
          <TabPane tab="支出" key="expend"></TabPane>
          <TabPane tab="收入" key="income"></TabPane>
        </Tabs>
        <SearchTemplate getData={search} selectData={selectData} />
      </div>
      <div className="space-height"></div>
      <div className="list-container">
        <TableWrap
          rowKey={"billId"}
          columns={columns}
          pageList={billData}
          onPageChange={onPageChange}
          currentPage={currentPage}
          total={total}
          isLoading={isLoading}
          sticky={{ offsetHeader: 330 }}
          isSummary /* 数据汇总 */
        />
      </div>
      <EditModal ref={modalRef} selectData={selectData} getData={search} />
    </Card>
  );
}

export default connect(state => ({ commonStore: state.commonStore }))(Bill);
