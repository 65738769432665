import React, { useState, useEffect, useRef } from 'react'
import { Menu } from 'antd';
import { Link, withRouter } from 'react-router-dom'
import {
    LayoutOutlined,
    AccountBookOutlined,
    FundProjectionScreenOutlined,
    SettingOutlined
} from '@ant-design/icons'
import logo from '../../../assets/images/logo2.png'
import './index.less'
import { connect } from 'react-redux';
const { SubMenu, Item } = Menu
function MenuWrap (props) {
    // 获取当前路由地址
    const path = props.location.pathname
    let openKey = useRef('')
    const [count, setcount] = useState(0)

    let menuArr = props.userInfo.adminMenuRespDtoList
    useEffect(() => {
        if (path === '/bill') {
            openKey.current = '/bill'
            setcount(count + 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userInfo.adminMenuRespDtoList, path]);
    // 处理tree数据
    const setMenuTree = menu => {
        return menu.map(item => {
            if (item.adminMenuNodeRespDtoList.length === 0 || item.parentMenuId === 0) {
                return {
                    menuId: item.menuId,
                    title: item.menuName,
                    key: item.menuPath,
                    icon: item.icon,
                    children: setMenuTree(item.adminMenuNodeRespDtoList).length > 0 ? setMenuTree(item.adminMenuNodeRespDtoList) : null
                }
            } else {
                return []
            }
        })
    }
    // 渲染菜单数据
    function showMenuList (menuList) {
        if (menuList) {
            return menuList.map(item => {
                let menuIcon = ''
                switch (item.title) {
                    case '资金看板':
                        menuIcon = <LayoutOutlined />
                        break
                    case '报表中心':
                        menuIcon = <FundProjectionScreenOutlined />
                        break
                    case '资金台账':
                        menuIcon = <AccountBookOutlined />
                        break
                    case '系统设置':
                        menuIcon = <SettingOutlined />
                        break
                    default:
                        menuIcon = ''
                        break
                }
                if (!item.children || item.children.length === 0) {
                    return (
                        <Item icon={menuIcon} key={item.key}>
                            <Link to={item.key}>{item.title}</Link>
                        </Item>
                    )
                } else {
                    if (item.menuId === 1 || item.menuId === 6) {
                        return showMenuList(item.children)
                    } else {
                        const cItem = item.children.find(cItem => cItem.key === path)
                        if (cItem) {
                            openKey.current = item.key
                        }
                        return (
                            <SubMenu key={item.key} icon={menuIcon} title={item.title}>
                                {showMenuList(item.children)}
                            </SubMenu>
                        )
                    }
                }
            })
        }
    }
    // 切换菜单回调
    const onOpenChange = (val) => {
        openKey.current = val[val.length - 1]
    }
    const menuListData = showMenuList(setMenuTree(menuArr))
    return (
        <>
            <Link to="/" className="logo">
                <img src={logo} alt="" />
                <div className='title'>
                    <div>倍增财资</div>
                    <div>管理智能专家系统</div>
                </div>
            </Link>
            <Menu key={count} theme="dark" mode="inline" selectedKeys={[path]}
                defaultOpenKeys={[openKey.current]} onOpenChange={onOpenChange}>
                {
                    menuListData
                }
            </Menu>
        </>
    )
}

export default connect(
    state => ({ userInfo: state.userInfo.userInfo })
)(withRouter(MenuWrap))