import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef
} from "react";
import {
  Modal,
  Form,
  Space,
  Button,
  Input,
  Switch,
  message,
  Radio,
  Checkbox
} from "antd";
import { addRoleApi, updateRoleApi } from "../../../../../api/admin-users";

const EditRoleModal = (props, ref) => {
  const [formData, setFormData] = useState({});
  const [isModalVisible, setisModalVisible] = useState(false);
  const [isSwitch, setisSwitch] = useState(true);
  const [radioValue, setradioValue] = useState(1);
  const [branchIds, setBranchIds] = useState([]);
  const formRef = useRef();
  const plainOptions = [
    { label: "全部", value: 1 },
    { label: "个人", value: 0 }
  ];
  useImperativeHandle(ref, () => {
    return {
      initFn: data => {
        setisModalVisible(true);
        setFormData(data);
        if (data) {
          setisSwitch(data.status)
          let timer = setTimeout(() => {
            clearTimeout(timer);
            let branchArr = data.branchId ? data.branchId.split(',') : []
            if(branchArr.length > 0) {
                branchArr = branchArr.map(item => Number(item))
            }
            formRef.current.setFieldsValue({
              ...data,
              type: data.type ? 1 : 0,
              branchId: branchArr
            });
            setradioValue(data.type ? 1 : 0)
          });
        } else {
          setisSwitch(true)
          let timer = setTimeout(() => {
            clearTimeout(timer);
            formRef.current.resetFields();
          });
        }
      }
    };
  });
  const onFinish = async val => {
    let res;
    if (!formData) {
      // 添加
      res = await addRoleApi({
        ...val,
        status: isSwitch,
        type: radioValue,
        branchId: branchIds.join(",")
      });
    } else {
      // 编辑
      res = await updateRoleApi({
        ...val,
        roleId: formData.roleId,
        status: isSwitch,
        type: radioValue,
        branchId: branchIds.join(",")
      });
    }
    if (res.code === 200) {
      message.success("操作成功");
      props.getData();
    } else {
      message.error(res.msg);
    }
    setisModalVisible(false);
  };

  const onSwitchChange = val => {
    setisSwitch(val);
  };
  const onRadioChange = e => {
    setradioValue(e.target.value);
  };
  // 选择部门
  const showFormItem = () => {
    if (radioValue) {
      return (
        <Form.Item label="部门" name="branchId">
          <Checkbox.Group
            options={props.branchList}
            value={branchIds}
            style={{ margin: "10px" }}
            onChange={onCheckChange}
          />
        </Form.Item>
      );
    }
    return;
  };
  const onCheckChange = val => {
    setBranchIds(val);
  };
  return (
    <Modal
      title={formData ? "修改" : "添加"}
      visible={isModalVisible}
      footer={null}
      onCancel={() => setisModalVisible(false)}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 16 }}
        ref={formRef}
        onFinish={onFinish}
        initialValues={{ type: 1 }}
      >
        <Form.Item
          label="角色名称"
          name="roleName"
          rules={[{ required: true, message: "请输入角色名称" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="角色描述" name="roleDesc">
          <Input />
        </Form.Item>
        <Form.Item label="查询类型" name="type">
          <Radio.Group
            options={plainOptions}
            onChange={onRadioChange}
            value={radioValue}
          />
        </Form.Item>
        {showFormItem()}
        <Form.Item label="角色状态" name="status">
          <Switch checked={isSwitch} onChange={onSwitchChange} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 24 }}
          style={{ textAlign: "right", marginTop: 30 }}
        >
          <Space size="middle">
            <Button htmlType="button" onClick={() => setisModalVisible(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default forwardRef(EditRoleModal);
