import { handleActions as createReducer } from "redux-actions";

import { saveFootTitle } from '../actions/admin.action'
const initState = localStorage.getItem('footTitle') ? JSON.parse(localStorage.getItem('footTitle')) : 'Copyright 2021 上海倍增智能科技有限公司 All Rights Reserved'
const saveFootTitleFn = (state, action) => {
    return action.payload ? action.payload : state
}

export default createReducer({
    [saveFootTitle]: saveFootTitleFn
}, initState)