export const excelReport = (res, title) => {
  var csvData = new Blob([res], { type: "application/vnd.ms-excel" });
  var file_name = title + ".xlsx";
  // for IE  判断是否IE
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(csvData, file_name);
  } else {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    var url = window.URL.createObjectURL(csvData);
    a.href = url;
    a.download = file_name;
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  }
};

// 处理大额数据
export const formatMoney = (s, type) => {
  if (/[^0-9\.]/.test(s)) return "0";
  if (s === null || s === "") return "0";
  s = s.toString().replace(/^(\d*)$/, "$1.");
  s = (s + "00").replace(/(\d*\.\d\d)\d*/, "$1");
  s = s.replace(".", ",");
  var re = /(\d)(\d{3},)/;
  while (re.test(s)) s = s.replace(re, "$1,$2");
  s = s.replace(/,(\d\d)$/, ".$1");
  if (type === 0) {
    // 不带小数位(默认是有小数位)
    var a = s.split(".");
    if (a[1] === "00") {
      s = a[0];
    }
  }
  return s;
};

// 获取当前时间
export const currentTimer = (date = new Date()) => {
  // console.log(new Date().getDate(), "date")
  // let date = new Date()
  let Y = date.getFullYear();
  let M =
    date.getMonth() + 1 > 10
      ? date.getMonth() + 1
      : "0" + (date.getMonth() + 1);
  let D = date.getDate() > 10 ? date.getDate() : "0" + date.getDate();
  return `${Y}-${M}-${D}`;
};

// 获取当前周的日期
export function getDateOfISOWeek(w, y) {
  // var simple = new Date(y, 0, 1 + (w - 1) * 7);
  // var dow = simple.getDay();
  // var ISOweekStart = simple;
  // if (dow <= 4) {
  //     ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  // } else {
  //     ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  // }
  // return ISOweekStart;
  // var test = moment().day("Monday").week(1);
  // let selectedWeekFirstDay = moment().weekYear(new Date().getFullYear()).week(3).day(1).format('YYYY-MM-DD')
  // let selectedWeekLastDay = moment().weekYear(new Date().getFullYear()).week(3).day(7).format('YYYY-MM-DD')
  // let ww1 = moment().quarter(2).startOf('quarter').format('YYYY-MM-DD')
  // let ww = moment().quarter(2).endOf('quarter').format('YYYY-MM-DD')
  // let selectedWeekDay = [selectedWeekFirstDay, selectedWeekLastDay]
  // console.log(/* selectedWeekDay */ ww1, ww)
}

export const watermark = (
  {
    // 使用 ES6 的函数默认值方式设置参数的默认取值
    // 具体参见 https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Functions/Default_parameters
    container = document.body,
    // width = '220px',
    // height = '120px',
    width = "160px",
    height = "90px",
    textAlign = "left",
    textBaseline = "bottom",
    font = "16px Microsoft Yahei",
    fillStyle = "rgba(184, 184, 184, 0.2)",
    content = "loading",
    rotate = "24",
    zIndex = 9999,
  } = {},
  ...res
) => {
  const args = res;
  const canvas = document.createElement("canvas");

  canvas.setAttribute("width", width);
  canvas.setAttribute("height", height);
  const ctx = canvas.getContext("2d");

  ctx.fillStyle = fillStyle;
  ctx.font = font;
  ctx.textAlign = textAlign;
  ctx.textBaseline = textBaseline;
  ctx.rotate((-15 * Math.PI) / 180);
  // drawText(ctx, content, 0, 60, 150)
  ctx.fillText(content, 0, 70);
  ctx.rotate("15*Math.PI/180"); //坐标系还原

  const base64Url = canvas.toDataURL();
  const __wm = document.querySelector(".__wm");
  const watermarkDiv = __wm || document.createElement("div");
  const styleStr = `
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index:${zIndex};
        pointer-events:none;
        background-repeat:repeat;
        background-image:url('${base64Url}')`;

  watermarkDiv.setAttribute("style", styleStr);
  watermarkDiv.classList.add("__wm");

  if (!__wm) {
    container.style.position = "relative";
    container.insertBefore(watermarkDiv, container.firstChild);
  }

  const MutationObserver =
    window.MutationObserver || window.WebKitMutationObserver;
  if (MutationObserver) {
    let mo = new MutationObserver(function () {
      const __wm = document.querySelector(".__wm");
      // console.log(__wm)
      // 只在__wm元素变动才重新调用 __canvasWM
      if ((__wm && __wm.getAttribute("style") !== styleStr) || !__wm) {
        // 避免一直触发
        mo.disconnect();
        mo = null;
        watermark(JSON.parse(JSON.stringify(args)));
      }
    });

    mo.observe(container, {
      attributes: true,
      subtree: true,
      childList: true,
    });
  }
};

/* function drawText (context, t, x, y, w) {
    // var chr = t.split("");
    // var temp = "";
    // var row = [];
    var row = t.split('/')

    // for (var a = 0; a < chr.length; a++) {
    //     if (context.measureText(temp).width < w) {
    //         ;
    //     }
    //     else {
    //         row.push(temp);
    //         temp = "";
    //     }
    //     temp += chr[a];
    // }

    // row.push(temp);
    // console.log(temp)
    // console.log(row)

    for (var b = 0; b < row.length; b++) {
        context.fillText(row[b], x, y + (b + 1) * 20);
    }
} */

// /bill/exportBill 导出
// /home/uploadExcel 上传
// /report/dailyMoneyReport 资金报表
// /report/moneyDetailsReport 部门资金明细表权限
// /sysLog/setCopyrightLogo 设置版权标识
// 按钮权限
export function isJurisdiction(type, list) {
  if (list && list.length > 0) {
    return list.findIndex((item) => item === type) >= 0;
  } else {
    return false;
  }
}

// 获取地址栏中code参数
export function getQueryVariable(variable) {
  /**
   * 判断当前url是否携带参数(钉钉扫码登陆)
   */
  let query = window.location.search.substring(1);
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}
