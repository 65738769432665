import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import chartFormat from "../../../../utils/chartFormat";
import moment from "moment";

export default function Chart(props) {
  let result;
  if (props.pageData) {
    result = chartFormat(props.pageData, props.currentRadio, props.chartId);
  }
  let myChart = useRef();
  useEffect(() => {
    if (
      myChart.current !== null &&
      myChart.current !== "" &&
      myChart.current !== undefined
    ) {
      myChart.current.dispose(); //销毁
    }
    const chartDom = document.getElementById(props.chartId);
    myChart.current = echarts.init(chartDom);
    myChart.current.getZr().on("click", clickHandle);
    myChart.current.setOption(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageData]);

  function clickHandle(params) {
    let pointInPixel = [params.offsetX, params.offsetY];
    if (myChart.current.containPixel("grid", pointInPixel)) {
      let xIndex = Math.abs(
        myChart.current.convertFromPixel({ seriesIndex: 0 }, [
          params.offsetX,
          params.offsetY
        ])[0]
      );
      let arr = [];
      let timer = {
        beginTime: "",
        endTime: ""
      };
      for (let key in props.pageData) {
        arr.push(key);
      }
      switch (props.currentRadio) {
        case 1: // 年份
          timer.beginTime = `${arr[xIndex]}-01-01`;
          timer.endTime = `${arr[xIndex]}-12-31`;
          break;
        case 2: // 季度
          timer.beginTime = moment()
            .weekYear((arr[xIndex] + "").slice(0, 4))
            .quarter((arr[xIndex] + "").slice(4))
            .startOf("quarter")
            .format("YYYY-MM-DD");
          timer.endTime = moment()
            .weekYear((arr[xIndex] + "").slice(0, 4))
            .quarter((arr[xIndex] + "").slice(4))
            .endOf("quarter")
            .format("YYYY-MM-DD");
          break;
        case 3: // 月份
          timer.beginTime = `${props.yearM}-${
            arr[xIndex] >= 10 ? arr[xIndex] : "0" + arr[xIndex]
          }-01`;
          timer.endTime = `${moment([props.yearM, 0, 31])
            .month(xIndex)
            .format("YYYY-MM-DD")}`;
          break;
        case 4: // 周
          timer.beginTime = moment()
            .weekYear(new Date().getFullYear())
            .week(arr[xIndex] - 1)
            .day(1)
            .format("YYYY-MM-DD");
          timer.endTime = moment()
            .weekYear(new Date().getFullYear())
            .week(arr[xIndex] - 1)
            .day(7)
            .format("YYYY-MM-DD");
          break;
        case 5: // 日
          timer.beginTime = moment()
            .date(arr[xIndex].slice(8))
            .format("YYYY-MM-DD");
          timer.endTime = moment()
            .date(arr[xIndex].slice(8))
            .format("YYYY-MM-DD");
          break;
        default:
          break;
      }
      props.getData(timer);
    }
  }
  return <div id={props.chartId} style={{ width: "100%", height: 380 }}></div>;
}
