import React, { Component } from 'react';
import { Card, Table, Tag, Button, Space, Row, Col, /* Cascader, */ message, Popconfirm } from 'antd'
import { getTreeListApi, delMenuApi } from '../../../../api/admin-users'
import EditModal from './modules/editModal'
import { getUserInfo } from '../../../../store/actions/user.action'
import { connect } from 'react-redux';
/* import TableWrap from '../../../../components/common/TableWrap/index' */

class MenuList extends Component {
    state = {
        columns: [{
            title: '菜单名称',
            dataIndex: 'menuName',
        },
        {
            title: '类型',
            dataIndex: 'isPage',
            render: text => <Tag color={text ? 'orange' : 'green'}>{text ? '菜单' : '目录'}</Tag>
        },
        {
            title: '菜单图标',
            dataIndex: 'icon',
        },
        {
            title: '菜单路径',
            dataIndex: 'menuPath',
        },
        {
            title: '排序',
            dataIndex: 'sort',
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: text => <Tag color={text ? 'green' : 'red'}>{text ? '正常' : '禁用'}</Tag>
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (text, record) => {
                return (
                    <Space>
                        <Button size="small" type="primary" onClick={() => this.state.modalRef.current.initFn(record)}>修改</Button>
                        <Popconfirm
                            title="是否要删除该菜单?"
                            onConfirm={() => this.deleteConfirm(record)}
                            onCancel={() => { }}
                            okText="确认"
                            cancelText="取消"
                        >
                            <Button size="small" danger type="primary">删除</Button>
                        </Popconfirm>
                    </Space>
                )
            }
        }],
        menuData: [],
        isLoading: true,
        parentMenuId: 0,
        modalRef: React.createRef(),
        /* pageSize: 10,
        currentPage: 1,
        total: 0 */
    }
    setMenuTree = menu => {
        return menu.map(item => {
            if (item.adminMenuNodeRespDtoList.length === 0 || item.parentMenuId === 0) {
                return {
                    parentMenuId: item.parentMenuId,
                    menuId: item.menuId,
                    menuName: item.menuName,
                    menuPath: item.menuPath,
                    isPage: item.isPage,
                    icon: item.icon,
                    sort: item.sort,
                    status: item.status,
                    children: this.setMenuTree(item.adminMenuNodeRespDtoList).length > 0 ? this.setMenuTree(item.adminMenuNodeRespDtoList) : null
                }
            } else {
                return []
            }
        })
    }
    componentDidMount () {
        this.getMenuData()
    }
    getMenuData = async () => {
        const { code, data } = await getTreeListApi({
            /* pageNo: this.state.currentPage,
            pageSize: this.state.pageSize */
        })
        if (code === 200) {
            this.setState({
                menuData: this.setMenuTree(data),
                isLoading: false,
                /* total: data.total */
            })
        }
    }
    deleteConfirm = (record) => {
        delMenuApi({ menuId: record.menuId }).then(res => {
            if (res.code === 200) {
                message.success('删除成功')
                this.getMenuData()
            } 
        })
    }
  /*   onPageChange = (current, size) => {
        this.setState({
            pageSize: size,
            currentPage: current
        })
    } */
    render () {
        const { columns, menuData, isLoading, modalRef/* , currentPage, total */ } = this.state
        return (
            <Card style={{ height: '100%' }}>
                <Row>
                    <Col><Button type="primary" onClick={() => modalRef.current.initFn()}>新增</Button></Col>
                </Row>
                <Table
                    rowKey={record => record.menuId}
                    columns={columns}
                    dataSource={menuData}
                    loading={isLoading}
                    style={{ marginTop: 10 }}
                />
                {/* <TableWrap
                    rowKey={'menuId'}
                    columns={columns}
                    pageList={menuData}
                    onPageChange={this.onPageChange}
                    currentPage={currentPage}
                    total={total}
                    isLoading={isLoading}
                    bordered
                /> */}
                <EditModal ref={modalRef} menuData={menuData} getData={this.getMenuData} getUserInfo={this.props.getUserInfo} />
            </Card>
        );
    }
}

export default connect(
    state => ({}),
    {
        getUserInfo
    }
)(MenuList);
