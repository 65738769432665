import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./index.less";
import logo from "../../assets/images/logo.png";
import ddLogo from "../../assets/images/dd.png";
import * as loginAction from "../../store/actions/user.action";
import { getQueryVariable } from "../../utils";
import { ddLoginApi } from "../../api/login";

function Login(props) {
  const { login, userToken } = props;
  const [isLoading, setisLoading] = useState(false);
  const history = useHistory();
  // 登录
  const onFinish = (values) => {
    login(values);
  };
  let REDIRECT_URL = encodeURIComponent("https://cw.beizengkj.com/");
  useEffect(() => {
    const token = localStorage.getItem("Authorization");
    if (token && token === userToken) {
      props.getUserInfo();
      setisLoading(true);
      setTimeout(() => {
        setisLoading(false);
        history.push("/");
      }, 1000);
    }
  }, [userToken]);
  let hanndleMessage = function (event) {
    let origin = event.origin;
    //判断是否来自ddLogin扫码事件。
    if (origin == "https://login.dingtalk.com") {
      let loginTmpCode = event.data; //拿到loginTmpCode后就可以在这里构造跳转链接进行跳转了
      if (loginTmpCode) {
        window.location.href =
          "https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=dingjmachxonql7mnyzt" +
          "&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=" +
          REDIRECT_URL +
          "&loginTmpCode=" +
          loginTmpCode;
        //拿到用户唯一标示然后在进行当前页面的调转，注意这里跳转以后还是会在当前页面只不过Url上带了参数了
      }
    }
  };
  useEffect(async () => {
    let goto = encodeURIComponent(
      "https://oapi.dingtalk.com/connect/qrconnect?appid=dingjmachxonql7mnyzt" +
        "&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=" +
        REDIRECT_URL
    );

    let code = getQueryVariable("code");
    if (code) {
      let state = getQueryVariable("state");
      let temp = {
        code: code,
        state: state,
      };
      // this.code = temp.code;
      let url = window.location.href;
      if (url.indexOf("?") != -1) {
        //判断是否存在参数
        url = url.replace(/(\?|#)[^'"]*/, ""); //去除参数
        window.history.pushState({}, 0, url);
      }
      // let res = await ddLoginApi({ code });
      // console.log(res, 'res')
      login({ code });

      //然后跳转首页
      // this.$router.replace({ path: "/home" });
    } else {
      window.DDLogin({
        id: "scanCode",
        goto: goto,
        style: "border:none;background-color:#FFFFFF;",
        width: "235",
        height: "290",
      });

      if (typeof window.addEventListener != "undefined") {
        window.addEventListener("message", hanndleMessage, false);
      } else if (typeof window.attachEvent != "undefined") {
        window.attachEvent("onmessage", hanndleMessage);
      }
    }
    return () => {
      window.removeEventListener("message", hanndleMessage, false);
    };
  }, []);
  return (
    <div className="admin-login">
      <div className="left-wrap">
        <div style={{ width: "100%", height: "60px" }}></div>
        <div className="title">倍增财资管理智能专家系统</div>
        <div className="bg-warp"></div>
      </div>
      <div className="right-wrap">
        <div className="from-box">
          <div className="login-logo">
            <img src={logo} alt="" />
          </div>
          <div className="login-title">
            <img className="dd-logo" src={ddLogo} alt="" />
            <p>
              打开
              <span>手机钉钉</span>
              扫一扫登录
            </p>
          </div>
          <div id="scanCode"></div>

          {/* <Form
                        name="normal_login"
                        className="login-form"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="userName"
                            rules={[{ required: true, message: '请输入用户名' }]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="输入用户名" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: '请输入密码' }]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="输入密码"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button loading={isLoading} type="primary" htmlType="submit" className="login-form-button">
                                登录
                            </Button>
                        </Form.Item>
                    </Form> */}
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({ userToken: state.userInfo.token }),
  (dispatch) => ({
    ...bindActionCreators(loginAction, dispatch),
  })
)(Login);
