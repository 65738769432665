import React, { Component } from "react";
import { message, Modal, Tree } from "antd";
import {
  getRoleMenuApi,
  getRoleResourceApi,
  addRoleMenuApi,
  addRoleResourceApi
} from "../../../../../api/admin-users";

class AddResources extends Component {
  state = {
    treeData: [],
    checkedKeys: [],
    selectedKeys1: [],
    expandedKeys: [],
    selectedKeys: [],
    selectedResourceKeys1: [],
    expandedResourceKeys: [],
    selectedResourceKeys: [],
    checkedResourceKeys: [],
    treeResourceData: []
  };
  // 实例创建完成
  async componentDidMount() {
    const tree = this.props.treeData[0].map((item, index) =>
      this.setRouteTree(item, index)
    );
    const resourTree = this.props.treeData[1].map((item, index) =>
      this.setResourceRouteTree(item)
    );
    // 获取角色相关菜单
    let checkedKeys;
    let res1 = await getRoleMenuApi({ roleId: this.props.roleId });
    if (res1.code === 200) {
      checkedKeys = res1.data.map(
        item => `${item.parentMenuId}-${item.menuId}`
      );
    }
    // 获取角色相关资源
    let checkedResourceKeys;
    let res2 = await getRoleResourceApi({ roleId: this.props.roleId });
    if (res2.code === 200) {
      checkedResourceKeys = res2.data.map(
        item => `${item.cateId}-${item.sourceId ? item.sourceId : 0}`
      );
    }
    this.setState({
      treeData: tree,
      checkedKeys,
      treeResourceData: resourTree,
      checkedResourceKeys,
      selectedKeys: this.keysArr(checkedKeys),
      selectedResourceKeys: this.keysArr(checkedResourceKeys)
    });
  }
  // 整理树形结构数据
  setRouteTree = (org, index) => {
    // 遍历树  获取id数组
    const haveChildren =
      Array.isArray(org.adminMenuNodeRespDtoList) &&
      org.adminMenuNodeRespDtoList.length > 0;
    return {
      title: org.menuName,
      key: `${org.parentMenuId}-${org.menuId}`,
      // 判断它是否存在子集，若果存在就进行再次进行遍历操作，知道不存在子集便对其他的元素进行操作
      children: haveChildren
        ? org.adminMenuNodeRespDtoList.map((i, iIndex) =>
            this.setRouteTree(i, iIndex)
          )
        : []
    };
  };
  // 整理树形结构数据
  setResourceRouteTree = org => {
    const haveChildren =
      Array.isArray(org.adminResourceRespDtoList) &&
      org.adminResourceRespDtoList.length > 0;
    return {
      title: org.sourceId ? org.sourceName : org.cateName,
      key: `${org.cateId}-${org.sourceId ? org.sourceId : 0}`,
      // 判断它是否存在子集，若果存在就进行再次进行遍历操作，知道不存在子集便对其他的元素进行操作
      children: haveChildren
        ? org.adminResourceRespDtoList.map((i, iIndex) =>
            this.setResourceRouteTree(i, iIndex)
          )
        : []
    };
  };
  handleOk = async () => {
    message.loading("保存中...");
    Promise.all([
      addRoleMenuApi({
        paramIds: this.state.selectedKeys,
        roleId: this.props.roleId
      }),
      addRoleResourceApi({
        paramIds: this.state.selectedResourceKeys,
        roleId: this.props.roleId
      })
    ])
      .then(res => {
        message.destroy();
        this.props.getUserInfo();
        message.success(res[1].msg);
        this.props.getData(false);
      })
      .catch(err => {
        console.log(err, "--------------");
      });
  };
  handleCancel = () => {
    this.props.getData(false);
  };
  keysArr = treeArr => {
    let keyArr = [];
    treeArr.forEach(item => {
      const arr = item.split("-");
      keyArr.push(Number(arr[arr.length - 1]));
    });
    return keyArr;
  };
  // 菜单权限分配
  onCheck = checkedKeys => {
    this.setState({
      selectedKeys: Array.from(new Set(this.keysArr(checkedKeys))),
      checkedKeys
    });
  };
  onSelect = selectedKeys => {
    this.setState({
      selectedKeys1: selectedKeys
    });
  };
  onExpand = expandedKeys => {
    this.setState({
      expandedKeys
    });
  };
  // 资源权限分配
  onResourceCheck = checkedKeysValue => {
    this.setState({
      selectedResourceKeys: Array.from(new Set(this.keysArr(checkedKeysValue))),
      checkedResourceKeys: checkedKeysValue
    });
  };
  onResourceSelect = selectedKeysValue => {
    this.setState({
      selectedResourceKeys1: selectedKeysValue
    });
  };
  onResourceExpand = expandedKeysValue => {
    this.setState({
      expandedResourceKeys: expandedKeysValue
    });
  };
  render() {
    const {
      expandedKeys,
      selectedKeys1,
      checkedKeys,
      treeData,
      expandedResourceKeys,
      selectedResourceKeys1,
      checkedResourceKeys,
      treeResourceData
    } = this.state;
    return (
      <Modal
        title="分配权限"
        visible={true}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            overflowY: "scroll",
            height: 320
          }}
        >
          <div>
            <span
              style={{
                fontWeight: "bold",
                display: "inline-block",
                marginBottom: 10
              }}
            >
              菜单权限分配
            </span>
            {/* 菜单权限分配 */}
            <Tree
              checkable
              expandedKeys={expandedKeys}
              checkedKeys={checkedKeys}
              selectedKeys={selectedKeys1}
              onCheck={this.onCheck}
              onSelect={this.onSelect}
              treeData={treeData}
              onExpand={this.onExpand}
            />
          </div>
          <div>
            <span
              style={{
                fontWeight: "bold",
                display: "inline-block",
                marginBottom: 10
              }}
            >
              资源权限分配
            </span>
            {/* 资源权限分配 */}
            <Tree
              checkable
              expandedKeys={expandedResourceKeys}
              checkedKeys={checkedResourceKeys}
              selectedKeys={selectedResourceKeys1}
              onCheck={this.onResourceCheck}
              treeData={treeResourceData}
              onSelect={this.onResourceSelect}
              onExpand={this.onResourceExpand}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default AddResources;
