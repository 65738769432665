import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import zhCN from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd'
import store from './store'
import App from './App'


ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <ConfigProvider locale={zhCN}>
                <App />
            </ConfigProvider>
        </HashRouter>
    </Provider>
    ,
    document.getElementById('root')
)