import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Row,
  Col,
  Switch,
  Button,
  Input,
  Popconfirm,
  message,
  Select,
  Space
} from "antd";
import {
  findBankAccountPageApi,
  editBankAccountStatusApi,
  getUserChooseApi
} from "../../../../api/admin-users";
import { connect } from "react-redux";
import EditBankModal from "./modules/editBankModal";
import TableWrap from "../../../../components/common/TableWrap/index";
import { isJurisdiction } from "../../../../utils";
const { Option } = Select;

const Bank = props => {
  const columns = [
    {
      title: "绑定用户",
      dataIndex: "userName"
    },
    {
      title: "银行账户",
      dataIndex: "bankAccount"
    },
    {
      title: "部门",
      dataIndex: "branchName"
    },
    {
      title: "余额",
      dataIndex: "balance"
    },
    {
      title: "状态",
      dataIndex: "status",
      render: (text, record) => {
        let isShow = isJurisdiction(
          "/bank/editBankAccountStatus",
          props.userInfo.resourceList
        );
        if (isShow) {
          return (
            <Popconfirm
              title={`确定${text ? "关闭" : "开启"}状态`}
              onConfirm={() => switchConfirm(record)}
              onCancel={() => ({})}
              okText="确定"
              cancelText="取消"
            >
              <Switch checked={text} />
            </Popconfirm>
          );
        } else {
          return <Switch checked={text} disabled={!isShow} />;
        }
      }
    },
    {
      title: "创建时间",
      dataIndex: "createTime"
    },
    {
      title: "备注",
      dataIndex: "memo"
    },
    {
      title: "操作",
      dataIndex: "actions",
      render: (text, record) => {
        if (
          isJurisdiction("/bank/editBankAccount", props.userInfo.resourceList)
        ) {
          return (
            <Button
              size="small"
              onClick={() => modalRef.current.initFn(record)}
              style={{
                background: "#f6a000f5",
                borderColor: "#f6a000f5",
                color: "#fff"
              }}
            >
              编辑
            </Button>
          );
        }
      }
    }
  ];
  const [userList, setuserList] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [searchParams, setSearchParams] = useState({
    bankAccount: "",
    userId: "",
    status: -1
  });
  const [pageSize, setpageSize] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [total, settotal] = useState(0);
  const [selectUser, setSelectUser] = useState([]);
  const [branchId, setbranchId] = useState();
  const [inputValue, setInputValue] = useState();
  const [memo, setMemo] = useState();
  const [balanceCount, setBalanceCount] = useState(0);

  useEffect(() => {
    getBankAccountData(searchParams);
    // 获取用户信息
    getUserChooseApi().then(res => {
      setSelectUser(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, currentPage]);
  let modalRef = useRef();
  // 获取银行账户列表
  function getBankAccountData(params) {
    let searchP = JSON.parse(JSON.stringify(params));
    setisLoading(true);
    // const params = { ...searchParams }
    if (searchP.status === -1) {
      searchP.status = "";
    }
    if (searchP.branchId === -1) {
      searchP.branchId = "";
    }
    // TODO
    findBankAccountPageApi({
      data: {
        ...searchP,
        status: searchP.status
      },
      pageNo: currentPage,
      pageSize: pageSize
    }).then(res => {
      setisLoading(false);
      if (res.code === 200) {
        setuserList(res.data.respList.list);
        settotal(res.data.respList.total);
        setBalanceCount(res.data.balanceCount);
      }
    });
  }
  // 输入查询
  function handleInputChange(e) {
    if (e.type === "keyup" && e.keyCode === 13) {
      getBankAccountData({
        ...searchParams,
        bankAccount: e.target.value.replace(/\s+/g, "")
      });
    }
    setSearchParams({
      ...searchParams,
      bankAccount: e.target.value.replace(/\s+/g, "")
    });
    setInputValue(e.target.value.replace(/\s+/g, ""));
  }
  // 备注搜索
  function handlEmemoChange(e) {
    if (e.type === "keyup" && e.keyCode === 13) {
      getBankAccountData({
        ...searchParams,
        memo: e.target.value.replace(/\s+/g, "")
      });
    }
    setSearchParams({
      ...searchParams,
      memo: e.target.value.replace(/\s+/g, "")
    });
    setMemo(e.target.value.replace(/\s+/g, ""));
  }
  // 搜索
  function handleSearch() {
    getBankAccountData(searchParams);
  }
  // 确认气泡弹窗
  function switchConfirm(record) {
    editBankAccountStatusApi({
      accountId: record.accountId,
      status: !record.status
    }).then(res => {
      if (res.code === 200) {
        message.success("更改成功");
        getBankAccountData(searchParams);
      }
    });
  }
  function handleSelectChange(val) {
    let params = { ...setSearchParams, status: val };
    setSearchParams(params);
    getBankAccountData(params);
  }
  function onPageChange(current, size) {
    setpageSize(size);
    setcurrentPage(current);
  }
  // 部门多选查看收支明细
  function selectChange(val) {
    setbranchId(val);
    setSearchParams({ ...searchParams, branchId: val });
    getBankAccountData({ ...searchParams, branchId: val });
  }
  // 是否有新增权限
  function isShowAddBtn() {
    if (isJurisdiction("/bank/addBankAccount", props.userInfo.resourceList)) {
      return (
        <Button
          style={{ marginRight: 20 }}
          type="primary"
          onClick={() => modalRef.current.initFn()}
        >
          新增
        </Button>
      );
    }
  }
  // 用户筛选
  function selectDom() {
    let selectDom;
    if (props.userInfo.roleType) {
      selectDom = (
        <Select
          allowClear
          listHeight={180}
          showSearch
          onChange={handleChange}
          placeholder="选择用户名"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
        >
          {selectUser.map(item => {
            return (
              <Option key={item.userName} value={item.userId}>
                {item.userName}
              </Option>
            );
          })}
        </Select>
      );
    } else {
      selectDom = "";
    }
    return selectDom;
  }
  function handleChange(userId) {
    setSearchParams({ ...searchParams, userId });
    console.log(userId);
  }
  return (
    <Card>
      <Row>
        <Col>{isShowAddBtn()}</Col>
        <Col>
          <Space>
            <Input
              placeholder="输入银行账户"
              value={inputValue}
              onChange={handleInputChange}
              onKeyUp={handleInputChange}
              allowClear
            />
            <Input
              placeholder="输入备注查询"
              value={memo}
              onChange={handlEmemoChange}
              onKeyUp={handlEmemoChange}
              allowClear
            />
            <Select
              allowClear
              style={{ width: "200px" }}
              value={branchId}
              onChange={selectChange}
              placeholder="请选择部门"
            >
              <Option key={-1}>全部</Option>
              {props.departmentData.map(item => {
                return (
                  <Option key={item.branchId} value={item.branchId}>
                    {item.branchName}
                  </Option>
                );
              })}
            </Select>
            <Select
              defaultValue={-1}
              value={searchParams.status}
              style={{ width: 120 }}
              onChange={handleSelectChange}
            >
              <Option value={-1}>全部</Option>
              <Option value={1}>正常</Option>
              <Option value={0}>禁用</Option>
            </Select>
            {selectDom()}
            <Button
              type="primary"
              onClick={handleSearch}
              style={{ marginLeft: 10 }}
            >
              搜索
            </Button>
          </Space>
        </Col>
      </Row>
      <TableWrap
        rowKey={"accountId"}
        columns={columns}
        pageList={userList}
        onPageChange={onPageChange}
        currentPage={currentPage}
        total={total}
        isLoading={isLoading}
        bordered
        showBalance
        balanceCount={balanceCount}
      />
      <EditBankModal
        ref={modalRef}
        getData={() => getBankAccountData(searchParams)}
        departmentData={props.departmentData}
        userList={selectUser}
      />
    </Card>
  );
};

export default connect(state => ({
  departmentData: state.userInfo.userInfo.chooseRespList,
  userInfo: state.userInfo.userInfo
}))(Bank);
