import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Card, Button } from 'antd'
// import { Redirect } from 'react-router-dom'
import Layout from '../../components/Layout'
import { getUserInfo, logout } from '../../store/actions/user.action'
import { watermark } from '../../utils';
import moment from 'moment'
class Admin extends Component {
    state = {
        token: localStorage.getItem('Authorization')
    }
    // <Redirect to="/login" />
    componentDidMount () {
        if (this.state.token && this.props.departmentData && this.props.departmentData.length > 0) {
            /* ${this.props.departmentData[0].branchName}/ */
            watermark({
                content: `${this.props.userInfo.userName} ${moment().locale('zh-cn').format('YYYY-MM-DD')}`,
                container: document.querySelector('.ant-layout-content')
            })
            // 获取用户信息
            // this.props.getUserInfo()
            // 获取部门信息
            // let timer = setTimeout(() => {
            //     clearTimeout(timer)
            //     this.props.getDepartmentData()
            // })
        }
    }
    componentDidUpdate (prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            watermark({
                content: `${this.props.userInfo.userName} ${moment().locale('zh-cn').format('YYYY-MM-DD')}`,
                container: document.querySelector('.ant-layout-content')
            })
        }
    }
    getContent = () => {
        if (this.props.departmentData && this.props.departmentData.length === 0) {
            return (
                <Card style={{ fontSize: 18, fontWeight: 700, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    暂未绑定部门，请联系管理进行绑定，<span style={{ color: '#2a82cc' }}>绑定成功后刷新页面</span>
                    <Button style={{ position: 'absolute', right: 50 }} onClick={() => this.props.logout()}>退出</Button>
                </Card>
            )
        } else {
            return <Layout />
        }
    }
    render () {
        const { token } = this.state
        const { userToken } = this.props
        if (!token || token !== userToken) {
            return <Redirect to="/login" />
        }
        return (
            <div>
                {
                    this.getContent()
                }
            </div>
        );
    }
}

export default connect(

    state => ({
        userToken: state.userInfo.token,
        departmentData: state.userInfo.userInfo.chooseRespList,
        userInfo: state.userInfo.userInfo
    }),
    {
        getUserInfo,
        logout
    }
)(Admin);
