import React, { useState } from 'react'
import { Layout, Modal, Input } from 'antd';
import HeaderWrap from './Header'
import ContentWrap from './Content'
import MenuWrap from './Menu'
import { connect } from 'react-redux';
import { setCopyrightLogoApi } from '../../api/admin-users';
import { getFootTitle } from '../../store/actions/admin.action'
import { isJurisdiction } from '../../utils';
const { Footer, Sider } = Layout;
function LayoutWarp (props) {
    const [isVisible, setisVisible] = useState()
    const [title, settitle] = useState('')
    const handleUpdateTitle = () => {
        const isRole = isJurisdiction('/sysLog/setCopyrightLogo', props.userInfo.resourceList)
        if (isRole) {
            setisVisible(true)
        }
    }
    const onOk = () => {
        setCopyrightLogoApi({ copyrightLogo: title }).then(res => {
            if (res.code === 200) {
                props.getFootTitle()
            }
            setisVisible(false)
        })
    }
    return (
        <Layout >
            <Sider
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                }}
            >
                <MenuWrap />
            </Sider>
            <Layout style={{ marginLeft: 200, minHeight: '100vh' }}>
                <HeaderWrap />
                <ContentWrap />
                <Footer style={{ textAlign: 'center', cursor: 'pointer' }} onDoubleClick={handleUpdateTitle}>{props.adminInfo}</Footer>
            </Layout>
            <Modal title="修改版权名称" visible={isVisible} onOk={onOk} onCancel={() => setisVisible(false)}>
                <Input placeholder="请输入名称" onChange={e => settitle(e.target.value)} />
            </Modal>
        </Layout>
    )
}
export default connect(
    state => ({ adminInfo: state.adminInfo, userInfo: state.userInfo.userInfo }),
    {
        getFootTitle
    }
)(LayoutWarp)