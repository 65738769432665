import { GET, POST } from '../config/server'

/* 登录 */
export const loginApi = params => POST('/admin/login', params)
/* 退出 */
export const logoutApi = params => GET('/admin/loginOut', params)
/* 获取用户信息 */
export const getUserInfoApi = params => GET('/admin/getAdminUserInfo', params)
/* 修改密码 */
export const updatePassApi = params => POST('/admin/updatePass', params)
// 钉钉扫码登陆
export const ddLoginApi = params => POST('/admin/dingTalkLogin', params)

