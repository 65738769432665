import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { Modal, Form, Input, Button, Space, Switch, message, Select } from 'antd'
import { addBankAccountApi, editBankAccountApi } from '../../../../../api/admin-users';
const { Option } = Select
const EditUserModal = (props, ref) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [formData, setFormData] = useState({})
    const [isSwitch, setIsSwitch] = useState(true);
    const formRef = useRef()
    const modalRef = useRef()
    // 响应父组件调用方法
    useImperativeHandle(ref, () => {
        return {
            initFn: (data) => {
                setIsModalVisible(true)
                setFormData(data)
                let timer = setTimeout(() => {
                    clearTimeout(timer)
                    if (data) {
                        setIsSwitch(data.status)
                        let result = { ...data, branchId: props.departmentData.find(item => item.branchName === data.branchName).branchId }
                        formRef.current && formRef.current.setFieldsValue(result)
                    } else {
                        setIsSwitch(true)
                        formRef.current && formRef.current.resetFields()
                    }
                });
            }
        }
    })
    useEffect(() => {
        
    }, [])
    const onFinish = async (val) => {
        let res
        if (formData) {
            res = await editBankAccountApi({ ...val, accountId: formData.accountId })
        } else {
            res = await addBankAccountApi(val)
        }
        if (res.code === 200) {
            message.success('操作成功')
            props.getData()
        }
        setIsModalVisible(false)
    }
    const onSwitchChange = (val) => {
        setIsSwitch(val)
    }
    // 选择部门
    function handleChange (val) {
        // console.log(val)
    }
    function selectDom (type) {
        let result
        let key
        let value
        if (type === 'branchId') {
            result = props.departmentData
            key = 'branchId'
            value = 'branchName'
        } else {
            result = props.userList
            key = 'userId'
            value = 'userName'
        }
        return (
            <Select
                listHeight={180}
                showSearch
                onChange={handleChange}
                placeholder={type === 'branchId' ? "请选择所属部门" : "选择用户名"}
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }>
                {
                    result.map(item => {
                        return <Option key={item[key]} value={item[key]}>{item[value]}</Option>
                    })
                }
            </Select>
        )
    }
    return (
        <div ref={modalRef}>
            <Modal title={formData ? '修改' : '添加'} visible={isModalVisible} footer={null} onCancel={() => setIsModalVisible(false)}>
                <Form
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    name="basic"
                    initialValues={{ remember: true, status: true }}
                    onFinish={onFinish}
                    ref={formRef}
                >
                    <Form.Item
                        label="所属部门"
                        name="branchId"
                        rules={[{ required: true, message: '请选择所属部门' }]}
                    >
                        {selectDom('branchId')}
                    </Form.Item>
                    <Form.Item
                        label="绑定用户名"
                        name="userId"
                        rules={[{ required: true, message: '请选择绑定的用户名' }]}
                    >
                        {selectDom('userId')}
                    </Form.Item>

                    {/* <Form.Item
                        label="持卡人"
                        name="cardholder"
                        rules={[{ required: true, message: '请输入持卡人' }]}
                    >
                        <Input placeholder="请输入持卡人" />
                    </Form.Item>
                    <Form.Item
                        label="所属银行"
                        name="bank"
                        rules={[{ required: true, message: '请输入所属银行' }]}
                    >
                        <Input placeholder="请输入所属银行" />
                    </Form.Item> */}
                    <Form.Item
                        label="银行账户"
                        name="bankAccount"
                        rules={[
                            {
                                required: true,
                                message: '请输入银行账户'
                            }]}
                        getValueFromEvent={(event) => event.target.value.replace(/\s+/g, "")}
                    >
                        <Input placeholder="请输入银行账户" />
                    </Form.Item>
                    {/* <Form.Item
                        label="余额"
                        name="balance"
                        rules={[{ required: true, message: '请输入余额' }]}
                    >
                        <Input placeholder="请输入余额" />
                    </Form.Item> */}
                    <Form.Item
                        label="备注"
                        name="memo"
                    >
                        <Input placeholder="请输入备注" />
                    </Form.Item>
                    <Form.Item
                        label="状态"
                        name="status"
                    >
                        <Switch checked={isSwitch} onChange={onSwitchChange} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right', marginTop: 30 }}>
                        <Space size="middle">
                            <Button htmlType="button" onClick={() => setIsModalVisible(false)}>
                                取消
                            </Button>
                            <Button type="primary" htmlType="submit">
                                确定
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </div >
    );
}

export default forwardRef(EditUserModal);
